import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Container, CardImg } from 'react-bootstrap';
import vi_image from '../../Assets/images/VI1.png'
import Education from '../../Assets/images/education.png'
import { ErrorPage, LoadingPage } from './ErrorPage';
import { Navigation } from '../../App';

const cardItems = [
  {
    title: "Virtual Influencer",
    description: "A virtual influencer is a fictional character that is created to promote a brand or product on social media. They are computer-generated and can be designed to look like real people. They are also known as CGI influencers or virtual celebrities.",
    imgSrc: vi_image,
  },
  {
    title: "Education",
    description: "Education Group to share the knowledge.",
    imgSrc: Education,    
  },
];


const HomePage = ({ user, userData, chatClient }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { client } = useChatContext();
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    // Check if justSignedUp state is passed
    if (location.state?.justSignedUp) {
      // Refresh the page
      window.location.reload();
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleCardSelection = (title) => {
    setSelectedCard(title);
    navigate(`/focus-groups/${title}`);
  };

  return <div>
    <Container className='text-center' style={{ padding: 1, marginTop: 70 }}>
      <Navigation user={user} userData={userData} />

      <ContentComponent
        userData={userData}
        chatClient={chatClient}
        cardItems={cardItems} 
        handleCardSelection={handleCardSelection}/>
    </Container >
  </div>
};

const ContentComponent = (props) => {
  if (props.chatClient === null) {
    // When chatClient is still loading (null)
    return <LoadingPage />; // Replace with your loading component
  } else if (props.chatClient === undefined) {
    // When there is an error (undefined)
    return <ErrorPage />;
  } else {
    // When chatClient is available (valid object)
    return (
      <div>

        <div style={{ marginTop: 10 }}>
          <h2 className='display-3 font-weight-bold text-primary'>
            Welcome, {props.userData.nickname} ({props.userData.role ? props.userData.role : 'user'})!
          </h2>
          {/* <h3 className='mt-1'><b>Please select your world!</b></h3> */}
        </div>
        <CardsList items={props.cardItems} navigate={props.navigate}  handleCardSelection={props.handleCardSelection}/>
      </div>
    );
  }
};

const CardsList = ({ items, navigate, handleCardSelection }) => {
  return (
    <>
      {items.map((item) => {
        return <CardImage item={item} navigate={navigate} handleCardSelection={handleCardSelection} key={item.title}/>;
      })}
    </>
  );
};


const CardImage = ({ item, handleCardSelection }) => (
  <Card className="bg-white shadow border-0 mb-4 d-flex flex-row world-card-view"
    onClick={() => { 
      handleCardSelection(item.title);
      }}>


    <div style={{ flexBasis: '51.5%', position: 'relative' }}>

      <blockquote className="card-blockquote" style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', padding: '0 20px' }}>
        <h4 className="display-3 font-weight-bold text-primary">
          {item.title}
        </h4>
        <p className="">
          {item.description}
        </p>
      </blockquote>
    </div>
    <div style={{ flexBasis: '48.5%' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-bg"
        preserveAspectRatio="none"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        viewBox="0 0 583 100"
      >
        <polygon
          className="fill-white"
          points="299,0 299,100 350,100"
        />
      </svg>
      <CardImg
        alt="..."
        src={item.imgSrc}
        style={{ width: '100%', height: 300, objectFit: 'cover' }}
      />
    </div>
  </Card>
);

export default HomePage;