import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import './pages.css';


function Page3() {
  const [tiers, setTiers] = useState([]);
  const [currentTier, setCurrentTier] = useState('');
  const [newTier, setNewTier] = useState('');
  const [newQuestion, setNewQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [editForm, setEditForm] = useState({
    enabled: false,
    selectedQuestions: [],
    newQuestions: [],
  });

  useEffect(() => {
    const fetchTiers = async () => {
      try {
        const tiersCollection = await getDocs(
          collection(firestore, 'survey_questions')
        );
        const tierData = tiersCollection.docs.map((doc) => doc.id);
        setTiers(tierData);
      } catch (error) {
        console.error('Error fetching tiers: ', error);
      }
    };

    fetchTiers();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (currentTier) {
        try {
          const tierRef = doc(firestore, 'survey_questions', currentTier);
          const tierDoc = await getDoc(tierRef);

          if (tierDoc.exists()) {
            const tierData = tierDoc.data();
            if (tierData && 'questions' in tierData) {
              const tierQuestions = tierData.questions;
              setQuestions(tierQuestions);
            } else {
              setQuestions([]);
            }
          }
        } catch (error) {
          console.error('Error fetching questions: ', error);
        }
      }
    };

    fetchQuestions();
  }, [currentTier]);

  const handleDeleteSelectedQuestions = async () => {
    try {
      if (!currentTier || selectedQuestions.length === 0) {
        console.error('Please select a tier and at least one question to delete.');
        return;
      }

      const tierRef = doc(firestore, 'survey_questions', currentTier);
      const tierDoc = await getDoc(tierRef);

      if (tierDoc.exists()) {
        const tierData = tierDoc.data();
        const updatedQuestions = questions.filter(
          (_, index) => !selectedQuestions.includes(index)
        );

        await updateDoc(tierRef, {
          questions: updatedQuestions,
        });

        setSelectedQuestions([]); // Clear the selected questions
      }
    } catch (error) {
      console.error('Error deleting questions: ', error);
    }
  };

  const handleEditSelectedQuestions = () => {
    if (!currentTier || selectedQuestions.length === 0) {
      console.error('Please select a tier and at least one question to edit.');
      return;
    }

    const selectedQuestionsData = selectedQuestions.map((index) => {
      return {
        index,
        originalQuestion: questions[index].variations[0],
        newQuestion: questions[index].variations[0], // Display the original question
      };
    });

    setEditForm({
      enabled: true,
      selectedQuestions: selectedQuestionsData,
    });
  };

  const toggleQuestionSelection = (index) => {
    if (selectedQuestions.includes(index)) {
      setSelectedQuestions(selectedQuestions.filter((i) => i !== index));
    } else {
      setSelectedQuestions([...selectedQuestions, index]);
    }
  };

  const isQuestionSelected = (index) => selectedQuestions.includes(index);

  const handleAddQuestion = async () => {
    try {
      if (!currentTier && !newTier) {
        console.error('Please select a tier or enter a new tier name.');
        return;
      }

      let tierToAdd = currentTier;
      if (!tierToAdd) {
        tierToAdd = newTier;
        await setDoc(doc(firestore, 'survey_questions', newTier), {
          questions: [],
        });
        setTiers([...tiers, newTier]);
        setNewTier('');
      }

      if (questions.some((question) => question.variations[0] === newQuestion)) {
        alert('Question already exists in the tier.');
        return;
      }

      const tierRef = doc(firestore, 'survey_questions', tierToAdd);
      const tierDoc = await getDoc(tierRef);

      if (tierDoc.exists()) {
        const tierData = tierDoc.data();
        const newQuestionData = {
          variations: [newQuestion],
        };
        const updatedQuestions = [...(tierData.questions || []), newQuestionData];
        await updateDoc(tierRef, {
          questions: updatedQuestions,
        });

        setNewQuestion('');
      }
    } catch (error) {
      console.error('Error adding question: ', error);
    }
  };

  const handleAddNewTier = async () => {
    try {
      if (!newTier) {
        console.error('Please enter a new tier name.');
        return;
      }

      await setDoc(doc(firestore, 'survey_questions', newTier), {
        questions: [],
      });

      setTiers([...tiers, newTier]);
      setNewTier('');
    } catch (error) {
      console.error('Error adding new tier: ', error);
    }
  };

  const handleSaveEdit = async () => {
    if (!currentTier || !editForm.enabled) {
      console.error('Invalid edit parameters.');
      return;
    }

    const updatedQuestions = [...questions];

    for (const selectedQuestion of editForm.selectedQuestions) {
      updatedQuestions[selectedQuestion.index].variations[0] = selectedQuestion.newQuestion;
    }

    const tierRef = doc(firestore, 'survey_questions', currentTier);
    await updateDoc(tierRef, {
      questions: updatedQuestions,
    });

    setEditForm({ enabled: false, selectedQuestions: [] });
  };

  const handleCancelEdit = () => {
    setEditForm({ enabled: false, selectedQuestions: [] });
  };

  const handleMoveQuestionUp = (index) => {
    if (index > 0) {
      const updatedQuestions = [...questions];
      const questionToMove = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index - 1];
      updatedQuestions[index - 1] = questionToMove;

      // Update the state with the rearranged list of questions
      setQuestions(updatedQuestions);

      // Update the question order in the database
      updateQuestionOrder(updatedQuestions);
    }
  };

  const handleMoveQuestionDown = (index) => {
    if (index < questions.length - 1) {
      const updatedQuestions = [...questions];
      const questionToMove = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index + 1];
      updatedQuestions[index + 1] = questionToMove;

      // Update the state with the rearranged list of questions
      setQuestions(updatedQuestions);

      // Update the question order in the database
      updateQuestionOrder(updatedQuestions);
    }
  };

  const updateQuestionOrder = async (updatedQuestions) => {
    try {
      if (!currentTier) {
        console.error('Please select a tier to update question order.');
        return;
      }

      const tierRef = doc(firestore, 'survey_questions', currentTier);

      // Update the 'questions' field with the updated order
      await updateDoc(tierRef, {
        questions: updatedQuestions,
      });
    } catch (error) {
      console.error('Error updating question order: ', error);
    }
  };

  return (
    <>
      <Container>
        <h3 className="h4 text-primary font-weight-bold mb-4 text-center">Manage Survey Tiers and Questions</h3>

        <h5 className="h5 text-primary font-weight-bold mb-4 text-center">Select or Enter a Tier</h5>
        <Row className='justify-content-center'>
          <Col lg="4">
            <div className="w-100">
              <FormGroup>
                <UncontrolledDropdown className='dropdown-menu-lg-end w-100'>
                  <DropdownToggle caret color="secondary" className="w-100 text-start">
                    {currentTier ? currentTier : "Select a Tier"}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <DropdownItem onClick={() => setCurrentTier("")}>Select a Tier</DropdownItem>
                    {tiers.map((tier) => (
                      <DropdownItem key={tier} onClick={() => setCurrentTier(tier)}>
                        {tier}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </div>
          </Col>
          <Col md="5">
            <FormGroup>
              <Input
                type="text"
                value={newTier}
                onChange={(e) => setNewTier(e.target.value)}
                placeholder="Enter a new tier"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Button color="primary" onClick={handleAddNewTier}>Add New Tier</Button>
            </FormGroup>
          </Col>
        </Row>
        <h5 className="h5 text-primary font-weight-bold mb-4 text-center">Add New Question</h5>
        <Row className='justify-content-center'>
          <Col lg="9">
            <FormGroup>
              <Input
                type="text"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                placeholder="Enter a new question"
              />
            </FormGroup>

          </Col>
          <Col md="3">
            <FormGroup>
              <Button color="primary" onClick={handleAddQuestion}>Add Question</Button>
            </FormGroup>

          </Col>
        </Row>
        <h5 className="h5 text-primary font-weight-bold mb-4 text-center">Questions in Tier</h5>
        <Row>
          <Col lg="12">

            <ul className='list-group'>
              {questions &&
                questions.map((question, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={isQuestionSelected(index)}
                      onChange={() => toggleQuestionSelection(index)}
                    />

                    {editForm.enabled &&
                      editForm.selectedQuestions.some((selQ) => selQ.index === index) ? (
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          value={
                            editForm.selectedQuestions.find((selQ) => selQ.index === index)
                              .newQuestion
                          }
                          onChange={(e) => {
                            const updatedQuestions = [...editForm.selectedQuestions];
                            const selectedQuestion = updatedQuestions.find((selQ) => selQ.index === index);
                            selectedQuestion.newQuestion = e.target.value;
                            setEditForm({
                              ...editForm,
                              selectedQuestions: updatedQuestions,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <p>{question.variations[0]}</p>
                    )}
                    <div className="btn-group">
                      <button
                        onClick={() => handleMoveQuestionUp(index)}
                        disabled={index === 0}
                        className="btn btn-secondary"
                      >
                        Up
                      </button>
                      <button
                        onClick={() => handleMoveQuestionDown(index)}
                        disabled={index === questions.length - 1}
                        className="btn btn-secondary"
                      >
                        Down
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        {editForm.enabled ? (
          <div className="mt-3 d-flex justify-content-center">
            <button onClick={handleSaveEdit} className="btn btn-primary me-2">Save</button>
            <button onClick={handleCancelEdit} className="btn btn-secondary">Cancel</button>
          </div>
        ) : (
          selectedQuestions.length > 0 && (
            <div className="mt-3 d-flex justify-content-center">
              <button onClick={handleEditSelectedQuestions} className="btn btn-primary me-2">Edit Selected</button>
              <button onClick={handleDeleteSelectedQuestions} className="btn btn-danger">Delete Selected</button>
            </div>
          ))
        }

      </Container>
    </>
    // <div>
    //   <h2>Manage Survey Tiers and Questions</h2>
    //   <div>
    //     <h3>Select or Enter a Tier</h3>
    //     <select
    //       value={currentTier}
    //       onChange={(e) => setCurrentTier(e.target.value)}
    //     >
    //       <option value="">Select a Tier</option>
    //       {tiers.map((tier) => (
    //         <option key={tier} value={tier}>
    //           {tier}
    //         </option>
    //       ))}
    //     </select>
    //     <input
    //       type="text"
    //       value={newTier}
    //       onChange={(e) => setNewTier(e.target.value)}
    //       placeholder="Enter a new tier"
    //     />
    //     <button onClick={handleAddNewTier}>Add New Tier</button>
    //   </div>
    //   <div className="questions-heading">
    //     <h3 className="questions-list">Add New Question</h3>
    //     <input
    //       type="text"
    //       value={newQuestion}
    //       onChange={(e) => setNewQuestion(e.target.value)}
    //       placeholder="Enter a new question"
    //     />
    //     <button onClick={handleAddQuestion}>Add Question</button>
    //   </div>

    //   <div>
    //     <h3>Questions in Tier: {currentTier}</h3>
    //     <ul>
    //       {questions &&
    //         questions.map((question, index) => (
    //           <li key={index}>
    //             <input
    //               type="checkbox"
    //               checked={isQuestionSelected(index)}
    //               onChange={() => toggleQuestionSelection(index)}
    //             />
    //             {editForm.enabled &&
    //             editForm.selectedQuestions.some((selQ) => selQ.index === index) ? (
    //               <div>
    //                 <input
    //                   type="text"
    //                   value={
    //                     editForm.selectedQuestions.find((selQ) => selQ.index === index)
    //                       .newQuestion
    //                   }
    //                   onChange={(e) => {
    //                     const updatedQuestions = [...editForm.selectedQuestions];
    //                     const selectedQuestion = updatedQuestions.find((selQ) => selQ.index === index);
    //                     selectedQuestion.newQuestion = e.target.value;
    //                     setEditForm({
    //                       ...editForm,
    //                       selectedQuestions: updatedQuestions,
    //                     });
    //                   }}
    //                 />
    //               </div>
    //             ) : (
    //               <p>{question.variations[0]}</p>
    //             )}
    //             <div>
    //               <button
    //                 onClick={() => handleMoveQuestionUp(index)}
    //                 disabled={index === 0}
    //               >
    //                 Up
    //               </button>
    //               <button
    //                 onClick={() => handleMoveQuestionDown(index)}
    //                 disabled={index === questions.length - 1}
    //               >
    //                 Down
    //               </button>
    //             </div>
    //           </li>
    //         ))}
    //     </ul>
    //     {editForm.enabled ? (
    //       <div>
    //         <button onClick={handleSaveEdit}>Save</button>
    //         <button onClick={handleCancelEdit}>Cancel</button>
    //       </div>
    //     ) : (
    //       selectedQuestions.length > 0 && (
    //         <div>
    //           <button onClick={handleEditSelectedQuestions}>Edit Selected</button>
    //           <button onClick={handleDeleteSelectedQuestions}>Delete Selected</button>
    //         </div>
    //       ))
    //     }
    //   </div>
    // </div>
  );
}

export default Page3;
