import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, getDoc, setDoc, doc, runTransaction, arrayUnion, onSnapshot } from 'firebase/firestore';
import { Card, Button, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import useAuth from '../../Authentication/useAuth';
import aiavatar1 from "../../Assets/images/AI-avatar-1.png";
import aiavatar2 from "../../Assets/images/AI-avatar-2.png";
import aiavatar3 from "../../Assets/images/AI-avatar-3.png";
import hybridavatar1 from "../../Assets/images/hybrid-avatar-1.png";
import hybridavatar2 from "../../Assets/images/hybrid-avatar-2.png";
import defaultAvatar from "../../Assets/images/default_avatar.png";
import { useChatContext, useTranslationContext } from 'stream-chat-react';
import styled from 'styled-components';
import { ErrorPage, LoadingPage } from './ErrorPage';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import dp from '../../Assets/images/welisten_logo-sr.png'

const StyledCard = styled(Card)`
box-shadow: 0 1px 2px 2px ${props => props.shadowColor};
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 4px 5px 4px ${props => props.shadowColor}; // Box-shadow for hover state
  }
`;

const selectRandomAvatar = (focusGroup) => {
    if (focusGroup.moderator.toLowerCase() === "ai") {
        // Array of AI avatars
        const aiAvatars = [aiavatar1, aiavatar2, aiavatar3];
        // Randomly select an avatar
        return aiAvatars[Math.floor(Math.random() * aiAvatars.length)];
        // return <></>
    } else if (focusGroup.moderator.toLowerCase() === "hybrid") {
        // Array of hybrid avatars
        const hybridAvatars = [hybridavatar1, hybridavatar2];
        // Randomly select an avatar
        return hybridAvatars[Math.floor(Math.random() * hybridAvatars.length)];
    } else {
        return defaultAvatar
    }
};

const instructions = "Please fill out this usability testing survey!!"

const JoinSurveyFeedback = ({ setLanguage }) => {
    // console.log('setLanguage', setLanguage)
    const { t, userLanguage } = useTranslationContext();
    // console.log('userLanguage', userLanguage)
    const { client } = useChatContext();
    const chatClient = client;
    const [focusGroups, setFocusGroups] = useState([]);
    const { userData } = useAuth();
    const stopListening = () => SpeechRecognition.stopListening();
    stopListening();
    const [channelsData, setChannelsData] = useState([]);
    
    const navigate = useNavigate();
    useEffect(() => {
        if (userData) {
            const surveyCollectionRef = doc(firestore, 'survey_collection', 'usability_test');

            const fetchFocusGroups = async () => {
                const focusGroupsSnapshot = await getDoc(surveyCollectionRef)
                if (focusGroupsSnapshot.exists()) {
                    const focusGroup = focusGroupsSnapshot.data();
                    focusGroup.id = `${focusGroup.survey_id}-${userData.userID}`
                    focusGroup.avatar = selectRandomAvatar({ moderator: 'AI' })
                    setFocusGroups([focusGroup]);
                } else {
                    console.log('Document not found');
                }
            };
            fetchFocusGroups();
        }
    }, [userData])

    useEffect(() => {
        console.log('focusGroups', focusGroups, 'userLanguage', userLanguage)
        const initChannel = async (focusGroup) => {
            const channel = client.channel('survey', `${focusGroup.id}`, {
                name: focusGroup.name,
                image: dp,
                open: true,
                started: false,
                completed: false,
                language: userLanguage,
            });
            await channel.watch();
            console.log('current channel', channel, channel.data.language)
            if (!channel.data.started && channel.data.completed == false) {
                console.log('channel not started', channel.data.started)
                await channel.truncate({
                    'hard_delete': true,
                    'skip_push': false,
                    'message': {
                        'text': t(instructions),
                        'user_id': 'chandana2401'
                    }
                });
                console.log('channel truncated');
            }
            else {
                console.log('channel started', channel.data.started, ' not truncating');
            }
            const channel_role = 'channel_moderator'
    
            await channel.addMembers([{ user_id: userData.userID, channel_role: channel_role }]);
            console.log('added members', `${focusGroup.id}`, [userData.userID], channel_role)
        }
        if (focusGroups.length > 0 && userData && client && userLanguage) {
            focusGroups.forEach((focusGroup) => {
                initChannel(focusGroup);
            })

        }
    }, [focusGroups, userData, client, userLanguage])

    useEffect(() => {
        if (client) {
            client.on('channel.updated', async (event) => {
                console.log('channel.updated', event)
                getAllChannelData(userData);
            });
            return () => {
                client.off('channel.updated')
            }
        }
    }, [client, userData])

    const getAllChannelData = async (userData) => {
        // Return early if userData is null
        if (!userData) {
            console.error('No userData provided');
            return;
        }

        const remoteChannelData = {};

        // Since userData is not null, proceed to set the filter
        const filter = { type: 'survey', members: { $in: [userData.userID] } };
        const sort = [{ last_message_at: -1 }];

        try {
            const channels = await chatClient.queryChannels(filter, sort, {
                watch: true,
                state: true,
                presence: true,
            });

            console.log('channels', channels);

            channels.forEach((channel) => {
                let onlineMembersCount = 0;
                Object.values(channel.state.members).forEach((member) => {
                    if (member.user.online) {
                        onlineMembersCount++;
                    }
                });

                remoteChannelData[channel.data.id] = {
                    channelID: channel.data.id,
                    name: channel.data.name,
                    data: channel.data,
                    onlineMembersCount,
                };
            });

            setChannelsData(remoteChannelData);
            console.log('getAllChannelData', remoteChannelData);

            return remoteChannelData;
        } catch (error) {
            console.error('Error fetching channels:', error);
            return {}; // or `return null;` or `return channelsData;` if channelsData is defined elsewhere
        }
    };




    useEffect(() => { console.log("channelsData", channelsData) }, [channelsData])
    const handleJoin = async (focusGroupInfo) => {
        navigate(`/survey/${focusGroupInfo.survey_id}/chat`);
    };

    const getStatusAndStyle = (completed) => {
        if (completed) {
            return { text: 'Open', style: { color: 'var(--green)' } };
        }
        return { text: 'Open', style: { color: '#F4A460' } };

    };
    if (chatClient == null) return <LoadingPage />
    if (chatClient == undefined) return <ErrorPage />

    return (
        // <div>
        <div className='text-center' style={{ padding: 1 }} >
            <Row style={{ marginTop: 20 }}>
                <Col xs={12} md={4} className="d-flex align-items-center justify-content-start">
                </Col>
                <Col xs={12} md={4} className="d-flex align-items-center justify-content-center">
                    <h2 style={{ color: "var(--primary)" }}>
                        {/* <strong>{t('Survey Selection')}</strong> */}
                    </h2>
                </Col>
                <Col md={4} className="d-none d-md-flex"> {/* Hidden on xs to md screens */}
                    {/* This column is empty, used to balance the layout */}
                </Col>
            </Row>



            <Row className="justify-content-center" style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
                {userData && focusGroups.map((focusGroup) => {
                    // console.log('channelsData', channelsData)
                    const currentChannelData = channelsData[`${focusGroup.survey_id}-${userData.userID}`]
                    let sessionStarted = false;
                    let moderator_id = null;
                    let moderator_info = null;
                    if (currentChannelData && currentChannelData.data) {
                        sessionStarted = currentChannelData.data.started ? currentChannelData.data.started : false;
                        moderator_id = currentChannelData.data.moderator_id ? currentChannelData.data.moderator_id : null;
                        moderator_info = currentChannelData.data.moderator_info ? currentChannelData.data.moderator_info : null;

                    }
                    const start_time = new Date()
                    const end_time = new Date(start_time);
                    end_time.setDate(end_time.getDate() + 14);
                    const completed_surveys = userData.completed_surveys ? userData.completed_surveys : {};
                    let surveyCompleted = false
                    let confirmationNum = null;
                    // console.log('completed_surveys', completed_surveys, focusGroup.survey_id, completed_surveys.includes(focusGroup.survey_id))
                    if (Object.keys(completed_surveys).includes(focusGroup.survey_id)) {
                        surveyCompleted = true
                        confirmationNum = completed_surveys[focusGroup.survey_id]
                    }
                    console.log('sessionStarted', currentChannelData)
                    return <Col xs={12} md={6} key={focusGroup.id} className="mb-3">
                        <StyledCard shadowColor={getStatusAndStyle(surveyCompleted).style.color} key={focusGroup.id}>
                            <Card.Footer>
                                {!surveyCompleted ? <Button
                                    onClick={() => {
                                        handleJoin(focusGroup);
                                    }}
                                    style={{ backgroundColor: 'peru', borderColor: 'peru',color: 'white' }}
                                >
                                    {sessionStarted ? t("Continue") : t("Join")}
                                </Button> :
                                    <Button
                                        variant='success'
                                        disabled
                                    >
                                        {t("Survey Completed")}
                                    </Button>
                                }
                            </Card.Footer>
                        </StyledCard>
                    </Col>
                }
                )}</Row>
        </div >
    );
};
export default JoinSurveyFeedback;
