// SurveyFeedback.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Chat,
    Channel,
    Window,
    MessageList,
    MessageInput,
    LoadingIndicator,
    useChatContext,
    useChannelActionContext,
    ChannelHeader,
    useChannelStateContext,
    ChatAutoComplete,
    EmojiIconLarge,
    EmojiPicker,
    SendButton,
    Tooltip,
    useMessageInputContext,
    useTranslationContext,
} from 'stream-chat-react';
import {
    getFirestore,
    doc,
    updateDoc,
    getDoc,
    setDoc,
} from 'firebase/firestore';
import { Alert, Button, ButtonGroup, Card, Col, Collapse, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useAuth from '../../Authentication/useAuth';
// import 'stream-chat-react/dist/css/index.css';

import 'stream-chat-react/dist/css/v2/index.css';
// import 'stream-chat-react/dist/css/index.css';

import { FaMicrophone } from 'react-icons/fa';
import ProgressBar from 'react-bootstrap/ProgressBar';
import unavailableImg from '../../Assets/images/unavailable.png'
import { FaGlasses } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import Tour from 'reactour';
import { IoMdSend } from "react-icons/io";
import { HiOutlineSpeakerWave, HiOutlineSpeakerXMark } from "react-icons/hi2";
import FlipNumbers from 'react-flip-numbers';
import microphone_permission_img from '../../Assets/images/microphone permission.JPG'
import { MD5 } from 'crypto-js';
import { storage } from '../../firebase';
import { generateUniqueId } from './QRCodeGenerator';
import { getDownloadURL, ref } from '@firebase/storage';

const firestore = getFirestore();
const assistantID = 'welisten_assistant';
function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
}

function useWindowHeight() {
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return height;
}

const CustomChannelHeader = (props) => {
    const { t, userLanguage } = useTranslationContext();
    const navigate = useNavigate();
    const { userData } = useAuth();
    const isModerator = true;
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState('');
    // Generate a unique 5 character ID
    const [userWordCount, setUserWordCount] = useState(0);
    const { channel } = useChannelStateContext();
    const [sessionStarted, setSessionStarted] = useState(false);

    const [allUserStats, setAllUserStats] = useState({});
    const [sessionStartTime, setSessionStartTime] = useState(null);

    // const [isSendingQuestion, setIsSendingQuestion] = useState(false);
    const [isAbleToAskQuestion, setIsAbleToAskQuestion] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const isMobile = windowWidth <= 767;
    // console.log('props.isTTSon', props.isTTSon);
    let synthesizeServer = process.env.REACT_APP_SYNTHESIZE
    if (!synthesizeServer)
        synthesizeServer = "https://synthesize-cudbkqbuua-uc.a.run.app"
    
    const playSynthesizedAudio = async (text, langCode = 'en-US', retryCount = 0) => {
        const modelNameEN = 'en-US-Wavenet-F';
        const modelNameES = 'es-US-Neural2-A';
        const hash = MD5(text).toString();
        const languageCode = langCode.startsWith('es') ? "es-US" : "en-US";
        const modelName = languageCode.startsWith('es') ? modelNameES : modelNameEN;
        const fileName = `audio/${modelName}/${hash}.mp3`;
        const synthesizeUrl = `${synthesizeServer}?text=${encodeURIComponent(text)}&langCode=${encodeURIComponent(langCode)}`;
        const fileRef = ref(storage, fileName);
        console.log('synthesizeUrl', synthesizeUrl);

        try {
            const audioUrl = await getDownloadURL(fileRef);
            console.log('Audio URL:', audioUrl);
            const audio = new Audio(audioUrl);
            props.setIsPlaying(true);
            await audio.play();
            audio.onended = () => {
                props.setIsPlaying(false);
            };
        } catch (error) {
            console.error('Error fetching audio file:', error);
            if (retryCount < 5) { // Set a maximum number of retries to avoid infinite loop
                console.log('Retrying...');
                // Trigger the synthesis if it's the first retry
                if (retryCount === 0) {
                    await fetch(synthesizeUrl);
                }
                // Wait for a few seconds before retrying
                await new Promise(resolve => setTimeout(resolve, 5000));
                // Retry with an increased retry count
                await playSynthesizedAudio(text, langCode, retryCount + 1);
            } else {
                console.error('Max retries reached. Unable to play audio.');
                // Handle the case where the audio file could not be fetched after retries
            }
        }
    };

    const handleUserMessage = async (event) => {
        const { user, message } = event;
        console.log('event', event)
        if (user && message && message.text && message.type != 'system') {
            const words = message.text.split(/\s+/).filter((word) => word !== '');
            const userID = user.id;
            console.log(userID, 'words', words)
           
            if (userID != assistantID) {
                setIsAbleToAskQuestion(true);
                if (currentProgress < surveyQuestions.length)
                    handleAskQuestion(true);
            }

        }
    };
    const handleChannelUpdate = async (event) => {
        const { channel } = event;
        // console.log('updated channel', channel, event);
        const members = channel.members;
        const remoteSessionStarted = channel.started;
        const remoteProgress = channel.currentProgress ? channel.currentProgress : 0;
        const remoteCurrentQuestion = channel.currentQuestion ? channel.currentQuestion : '';
        const remoteOpen = typeof channel.open === 'boolean' ? channel.open : true;
        const remoteSessionStartTime = channel.started_at ? channel.started_at : null;
        const remoteSessionEndTime = channel.ended_at ? channel.ended_at : null;

        setSessionStarted(remoteSessionStarted);
        props.setParentSessionStarted(remoteSessionStarted);
        setCurrentProgress(remoteProgress);
        setCurrentQuestion(remoteCurrentQuestion)
        setSessionStartTime(remoteSessionStartTime);


        const remoteUserStats = channel.userStats ? channel.userStats : {};

        // const newUserStats = remoteUserStats;
        // if (members) {
        //     members.forEach(member => {
        //         const memberId = member.user_id;
        //         // Check if memberId is not in remoteUserStats
        //         if (!remoteUserStats[memberId]) {
        //             const newUserStat = { wordCount: 0, user: member.user };
        //             console.log('newUserStat', newUserStat);
        //             newUserStats[memberId] = newUserStat;
        //         }
        //     });

        // }

        setAllUserStats(remoteUserStats);
        // const surveyChannelRef = doc(firestore, 'survey_channels', channel.id);

        if (remoteOpen == false) {
            props.setChannelClosed(true);
        }
        else {
            //write to firestore collection survey_channels using channel.id, using merge = true
            // const objectToWrite = {
            //     data: channel,
            //     surveyID: props.surveyID,
            //     members: members,
            //     userStats: newUserStats,
            //     language: userLanguage,
            // }
            // await setDoc(surveyChannelRef, objectToWrite, { merge: true });
            // console.log('updated firestore survey_channels', objectToWrite);
        }

    }
    const getChannelData = () => {

        console.log('init channel data', channel);
        // console.log('channel.state.messages', channel.state.messages);
        const members = channel.state.members;
        const remoteSessionStarted = channel.data.started;
        const remoteProgress = channel.data.currentProgress ? channel.data.currentProgress : 0;
        const remoteCurrentQuestion = channel.data.currentQuestion ? channel.data.currentQuestion : '';
        const remoteOpen = typeof channel.data.open === 'boolean' ? channel.data.open : true;
        const remoteSessionStartTime = channel.data.started_at ? channel.data.started_at : null;

        setSessionStarted(remoteSessionStarted);
        props.setParentSessionStarted(remoteSessionStarted);
        setCurrentProgress(remoteProgress);
        setCurrentQuestion(remoteCurrentQuestion)
        setSessionStartTime(remoteSessionStartTime);
        console.log('remoteOpen', remoteOpen);

        const remoteUserStats = channel.data.userStats ? channel.data.userStats : {};

        const newUserStats = remoteUserStats;

        setAllUserStats(remoteUserStats);

        if (remoteOpen == false) {
            props.setChannelClosed(true);
        }

    }
    
    const getAllMessages = async (writeToFirebase = true) => {
        try {
            console.log('Fetching channel messages', channel);
            const messages = channel.state.messages; // Get the messages from the channel state
            const data = channel.data
            // Log the fetched messages for debugging purposes
            console.log('channel.state.messages', messages);
            const objectToWrite = { data, messages, language: userLanguage };

            if (writeToFirebase) {
                // Reference to the document in the 'survey_channels' collection with the ID of the channel
                const surveyChannelRef = doc(firestore, 'welisten_feedback', channel.id);
                // Write the data to Firestore, merging with any existing document to avoid overwriting
                await setDoc(surveyChannelRef, objectToWrite, { merge: true });

                // Log the update
                console.log('updated firestore survey_channels with messages', channel.id, objectToWrite);
            }

            // Return the messages array whether or not it was written to Firestore
            return objectToWrite;
        } catch (error) {
            // Handle any errors that occur during the fetch/write process
            console.error('Error fetching messages or updating Firestore:', error);
            throw error; // Rethrow the error to handle it in the calling code if needed
        }
    };
    const exportAllMessages = async (objectToWrite = null) => {
        try {
            if (objectToWrite) {
                // Reference to the document in the 'survey_channels' collection with the ID of the channel
                const surveyChannelRef = doc(firestore, 'welisten_feedback_exports', channel.id);
                // Write the data to Firestore, merging with any existing document to avoid overwriting
                await setDoc(surveyChannelRef, objectToWrite, { merge: true });
                // Log the update
                console.log('exported firestore survey_channels with messages', channel.id, objectToWrite);
            }
        } catch (error) {
            // Handle any errors that occur during the fetch/write process
            console.error('Error fetching messages or updating Firestore:', error);
            throw error; // Rethrow the error to handle it in the calling code if needed
        }
    };

    useEffect(() => {
        getChannelData();
        const timer = setInterval(() => {
            // Check the condition before calling getAllMessages
            if (channel.data.started && channel.data.completed === false) {
                console.log('Automatic saving in progress...');
                getAllMessages(true); // Write to Firebase as well
            }
            else {
                console.log('Session not started or already completed. No automatic saving.');
            }
        }, 15000);

        // Clear the interval on cleanup
        return () => clearInterval(timer);
    }, [channel]);


    //set useeffect on currentProgress and isAbleToAskQuestion. if currentProgress is equal to surveyQuestions.length and isAbleToAskQuestion is true
    //which means session is over, send a system message to the channel to tell user they can either add more to the conversations or Stop the Session anytime.
    useEffect(() => {
        if (currentProgress >= surveyQuestions.length && isAbleToAskQuestion && userData) {
            const text = t('Please click the "SUBMIT FEEDBACK" button above to exit the room.');
            const thankyouText = t('Thank you for participating in the survey! You can stop the survey now by clicking the button above.');
            props.setShowMessageInput(false);
            fetch(
                `${process.env.REACT_APP_SERVER_URL}/api/sendMessage?channelID=${channel.id}&userID=${assistantID}&text=${thankyouText}`
            ).then(() => {
                channel.sendMessage({ text, user: { id: userData.userID }, type: 'system' });
                if (props.isTTSon) {
                    playSynthesizedAudio(thankyouText, userLanguage);
                }
            });
        }
        else {
            console.log('currentProgress', currentProgress, surveyQuestions.length, isAbleToAskQuestion)
        }
    }, [currentProgress, surveyQuestions, isAbleToAskQuestion, userData, props.isTTSon]);

    useEffect(() => {
        let timer;

        if (isAbleToAskQuestion && sessionStarted && userData) {
            console.log('timer starts now!');
            // Start a timer for 1 minute
            timer = setTimeout(() => {
            }, 60000); // 60000 milliseconds = 1 minute
        }

        // Clear the timer if isAbleToAskQuestion changes or on component unmount
        return () => clearTimeout(timer);
    }, [isAbleToAskQuestion, sessionStarted, userData]);


    const handleStartStopSession = async () => {
        try {
            // const focusGroupDocRef = doc(firestore, 'focus_groups', props.focusGroupId);

            if (!sessionStarted) { //{t('Start Session')}
                await channel.updatePartial({
                    set: {
                        started: true,
                        moderator_id: userData.userID,
                        moderator_info: userData,
                        currentProgress: 0,
                        currentQuestion: '',
                        started_at: new Date().toISOString(),
                        completed: false,
                    }
                })
                const text = t('You have started the survey! Please feel free to share your opinions.');
                await channel.truncate({
                    'hard_delete': true,
                    'skip_push': false,
                    'message': {
                        text,
                        'user_id': 'chandana2401'
                    }
                });
                //for all members in the channel, set their word count to 0
                const memberIds = Object.keys(channel.state.members);
                if (memberIds.length > 0) {
                    //set their word counts to 0
                    const newUserStats = {}
                    memberIds.forEach(memberId => {
                        const newUserStat = { wordCount: 0, user: channel.state.members[memberIds].user };
                        // console.log('newUserStat', newUserStat);
                        newUserStats[memberId] = newUserStat;

                    });
                    channel.updatePartial({ set: { userStats: newUserStats } });
                }
                await handleAskQuestion(true);
                setIsAbleToAskQuestion(false);
            } else { //Close session
                await channel.updatePartial({ set: { started: false, open: false, currentProgress: 0, currentQuestion: '' } })
                const end_time = new Date().toISOString();
                const objectToWrite = { data: channel.data, messages: channel.state.messages, language: userLanguage };
                objectToWrite.data.ended_at = end_time;
                exportAllMessages(objectToWrite);

                props.setChannelClosed(true);

                await channel.updatePartial({
                    set: {
                        started: false,
                        completed: true,
                        ended_at: end_time,
                    }
                })
            }
        } catch (error) {
            console.error('Error handling the session:', error.message);
        }
    };

    const handleLeaveSession = async () => {
        try {
            const { surveyID } = props;

            const focusGroupDocRef = doc(firestore, 'survey_collection', surveyID);
            const focusGroupDocSnapshot = await getDoc(focusGroupDocRef);

            if (focusGroupDocSnapshot.exists()) {
                const focusGroupData = focusGroupDocSnapshot.data();
                const channelName = focusGroupData.name;
                console.log('userData leave', userData)
                if (userData.language == 'en') {
                    navigate('/');
                }
                else {
                    if (userData.language)
                        navigate(`/${userData.language}`);
                }
            } else {
                console.error('Focus group document does not exist.');
            }
        } catch (error) {
            console.error('Error leaving the session:', error.message);
        }
    };

    useEffect(() => {
        const fetchSurveyQuestions = async () => {
            try {
                const focusGroupDocRef = doc(firestore, 'survey_collection', props.surveyID);
                const focusGroupDocSnapshot = await getDoc(focusGroupDocRef);

                if (focusGroupDocSnapshot.exists()) {
                    const focusGroupData = focusGroupDocSnapshot.data();
                    const selectedTopic = focusGroupData.selected_topic;

                    if (selectedTopic) {
                        const surveyDocRef = doc(firestore, 'survey_questions', selectedTopic);
                        const surveyDocSnapshot = await getDoc(surveyDocRef);

                        if (surveyDocSnapshot.exists()) {
                            const surveyData = surveyDocSnapshot.data();
                            const questionsArray = surveyData.questions || [];
                            const extractedQuestions = questionsArray.map((question) => question.variations);
                            setSurveyQuestions(extractedQuestions);
                            // console.log('surveyQuestions', extractedQuestions);
                        } else {
                            console.error(`Document '${selectedTopic}' does not exist in survey_questions collection.`);
                        }
                    } else {
                        console.error('No selected topic found in the focus group document.');
                    }
                } else {
                    console.error('Focus group document does not exist.');
                }
            } catch (error) {
                console.error('Error fetching survey questions:', error.message);
            }
        };


        fetchSurveyQuestions();

    }, [isModerator, props.focusGroupId]);

    const handleAskQuestion = async (force = false) => {
        if (force) {
            console.log('asking!')
            props.setIsSendingQuestion(true); // Indicate that a question is being sent
            setIsAbleToAskQuestion(false);
            const currentQuestion = surveyQuestions[currentProgress];
            // console.log('handleAskQuestion', force, currentProgress, surveyQuestions.length)
            if (currentQuestion && currentQuestion.length > 0 && currentProgress < surveyQuestions.length) {
                const selectedQuestion = t(currentQuestion[Math.floor(Math.random() * currentQuestion.length)].trim());
                // console.log('currentQuestion', currentQuestion, selectedQuestion);

                try {

                    const streamChatResponse = await fetch(
                        `${process.env.REACT_APP_SERVER_URL}/api/sendMessage?channelID=${channel.id}&userID=${assistantID}&text=${selectedQuestion}`
                    );

                    if (!streamChatResponse.ok) {
                        throw new Error('Error sending message from system');
                    } else {
                        if (props.isTTSon) {
                            await playSynthesizedAudio(selectedQuestion, userLanguage);
                        }
                        else {
                            console.log(props.isTTSon, 'props.isTTSon')
                        }
                        await channel.updatePartial({ set: { currentProgress: currentProgress + 1, currentQuestion: selectedQuestion } });
                        setSelectedQuestion('');
                    }
                } catch (error) {
                    console.error(error); // Handle the error appropriately
                }
                props.setIsSendingQuestion(false); // Reset the state to allow sending another question
                // setIsAbleToAskQuestion(true)

            }
        }
        else {
            // Show the alert
            setShowAlertModal(true);

            // Hide the alert after 3 seconds
            setTimeout(() => {
                setShowAlertModal(false);
            }, 3000);
        }

    };

    return (
        <div>
            <div style={{ justifyContent: 'start', alignItems: 'center' }}>

                {isModerator && props.mode == 'chat' ? <Row className='text-center'>
                    <Col md='2'>
                        <div className='text-left ml-3'>
                            {sessionStarted &&
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                                    <span>{t('Word Count: ')}</span>
                                    <strong className='text-primary' style={{ marginBottom: 0 }}>
                                        <FlipNumbers
                                            height={17}
                                            width={10}
                                            color="peru"
                                            background="transparent" // or '#ffffff' if you prefer a white background
                                            play
                                            // perspective={100}
                                            numbers={userWordCount.toString()} />
                                    </strong>
                                </div>
                            }

                            {sessionStarted && <div>
                                {t("Current Survey Time: ")} <Timer sessionStarted={sessionStarted} sessionStartTime={sessionStartTime} />
                            </div>}
                        </div>

                    </Col>
                    <Col md='1'></Col>
                    <Col md='6'>
    
                        {sessionStarted && <>
                            <div className='text-center mb-1'>
                                <p style={{ marginBottom: 1 }}>{t('Current Question: ')}</p>
                                <strong style={{ color: 'peru', fontSize: 17 }}>{t(currentQuestion.trim())}</strong>
                            </div>

                        </>
                        }

                    </Col>

                    <Col md="3" style={{ textAlign: 'right', marginRight: 0 }}>
                        {isModerator && (
                            (!sessionStarted && (
                                <Button
                                    style={{ backgroundColor: 'peru', borderColor: 'peru' }}
                                    onClick={handleStartStopSession}
                                    size={isMobile ? 'sm' : 'lg'}
                                >
                                    {t('Start')}
                                </Button>
                            )) ||
                            (sessionStarted && currentProgress >= surveyQuestions.length && isAbleToAskQuestion && (
                                <Button
                                    variant='outline-warning'
                                    onClick={handleStartStopSession}
                                    size={isMobile ? 'sm' : 'lg'}
                                    style={{ marginRight: 10 }}
                                >
                                    {t('Submit')}
                                </Button>
                            ))
                        )}

                        {currentProgress < surveyQuestions.length && <Button
                            variant='danger'
                            onClick={handleLeaveSession}
                            disabled={currentProgress >= surveyQuestions.length}
                            size={isMobile ? 'sm' : 'lg'}
                        >
                            {t('Leave')}
                        </Button>}

                    </Col>

                </Row>
                    : <Row className='text-center'>
                        <Col md='2'>
                            {/* <ChannelHeader {...props} /> */}
                            <div className='text-left ml-3 mb-2'>
                                {sessionStarted && props.mode == 'chat' && <p style={{ marginBottom: 0 }}>
                                    {t('Word Count: ')}<strong style={{ color: 'var(--bluedark)' }}>{userWordCount}
                                    </strong></p>}
                                {t("Current Survey Time: ")}  <Timer sessionStarted={sessionStarted} sessionStartTime={sessionStartTime} />
                            </div>
                        </Col>
                        <Col md='8'>
                            {sessionStarted && <div className='text-center'>
                                <p style={{ marginBottom: 1, marginTop: 5 }}>{t('Current Question: ')}</p>
                                <h5><strong style={{ color: 'peru' }}>{t(currentQuestion)}</strong></h5>
                            </div>}

                        </Col>
                        <Col md='2' style={{ textAlign: 'right' }}>
                            <Button
                                style={{ margin: 10 }}
                                variant='danger'
                                onClick={handleLeaveSession}
                            >
                                {t('Leave')}
                            </Button>
                        </Col>
                    </Row>
                }
                {sessionStarted && <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10 }}>
                    <ProgressBar style={{ height: 15 }}
                        animated now={currentProgress / surveyQuestions.length * 100} variant='warning'
                        max={100} /></div>}
            </div>
            <Modal
                show={showAlertModal}
                onHide={() => setShowAlertModal(false)}
                // backdrop={false} // Disable the backdrop
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    Please answer the current question first.
                </Modal.Header>
            </Modal>



        </div >

    );
};

// const FakeChannelHeader = (props) => {
//     const { t } = useTranslationContext();
//     const { channel } = useChannelStateContext();
//     const windowWidth = useWindowWidth();
//     const isMobile = windowWidth <= 767;
//     const sessionStartTime = new Date().toISOString();
//     useEffect(() => {
//         if (channel) {
//             const remoteSessionStarted = channel.data.started;
//             console.log('channel', channel, 'remoteSessionStarted', remoteSessionStarted)
//             props.setParentSessionStarted(remoteSessionStarted);
//         }

//     }, [channel]);
//     return (
//         <div>
//             <div style={{ justifyContent: 'start', alignItems: 'center' }}>

//                 <Row className='text-center'>
//                     <Col md='2'>
//                         {/* {!isMobile && <ChannelHeader {...props} />} */}

//                         <div className='text-left mx-2 stats-container' data-tour='current-stats'>
//                             <div style={{ marginBottom: 0 }}>
//                                 {t('Word Count: ')}<strong className='text-primary'><span style={{ color: 'peru' }}>100</span>
//                                 </strong></div>
//                             <span >{t("Current Survey Time: ")}  <Timer sessionStarted={true} sessionStartTime={sessionStartTime} /> </span>
//                         </div>

//                     </Col>
//                     <Col md='1'></Col>
//                     <Col md='6'>
//                         {/* <div className='mt-1'>
//                             <Button variant='primary' data-tour='next-question-demo'
//                                 size={isMobile ? 'sm' : 'lg'} >
//                                 Next Question
//                             </Button>
//                         </div> */}

//                         <div className='text-center mb-1' data-tour='current-question'>
//                             <p style={{ marginBottom: 1 }}>{t('Current Question: ')}</p>
//                             <strong style={{ color: 'peru', fontSize: 17 }}>{t('What is your first name?')}</strong>
//                             <div style={{ marginLeft: 20, marginRight: 20 }}>
//                                 <ProgressBar style={{ height: 15 }}
//                                     animated now={1 / 15 * 100}
//                                     max={100}
//                                     variant='warning'
//                                 />
//                             </div>
//                         </div>


//                     </Col>

//                     <Col md="3" style={{ textAlign: 'right', marginRight: 0 }}>


//                         <Button data-tour="start-session" style={{ backgroundColor: 'peru', borderColor: 'peru' }}
//                             size={isMobile ? 'sm' : 'lg'}>
//                             {t('Start Survey')}
//                         </Button>



//                         <Button
//                             variant='danger'
//                             data-tour="leave-session"
//                             size={isMobile ? 'sm' : 'lg'}
//                         >
//                             {t('Leave Survey')}
//                         </Button>
//                     </Col>
//                 </Row>

//             </div>
//         </div >

//     );
// };


export const CustomMessageInput = (props) => {
    const { t, userLanguage } = useTranslationContext();
    const { sendMessage } = useChannelActionContext();
    const {
        handleSubmit,
        additionalTextareaProps,
    } = useMessageInputContext();
    const [isSending, setIsSending] = useState(false);
    const { transcript, listening, resetTranscript } = useSpeechRecognition();
    const [editableTranscript, setEditableTranscript] = useState('');
    const [lastTranscript, setLastTranscript] = useState('');
    const { isTTSon, setIsTTSOn, isPlaying, setIsPlaying, channelClosed, placeholder, isSendingQuestion } = additionalTextareaProps
    let speechInLanguage = 'en-US';
    if (userLanguage == 'es') {
        speechInLanguage = 'es-MX';
    }
    const startListening = () => SpeechRecognition.startListening({ continuous: true, language: speechInLanguage });
    const stopListening = () => SpeechRecognition.stopListening();

    const prevIsPlayingRef = useRef();
    const prevListeningRef = useRef();
    console.log('additionalTextareaProps', additionalTextareaProps)

    if (channelClosed) {
        console.log('channelClosed! Stop listening')
        stopListening();
    }
    const toggleRecording = () => {
        if (listening) {
            SpeechRecognition.stopListening();
            setLastTranscript(transcript);
        } else {
            startListening();
        }
    };
    useEffect(() => {
        console.log(`isPlaying: ${isPlaying}, listening: ${listening}`);
        console.log(`prevIsPlaying: ${prevIsPlayingRef.current}, prevListening: ${prevListeningRef.current}`);

        if (isPlaying) {
            if (listening) {
                console.log('Stopping listening because isPlaying is true');
                stopListening();
                prevListeningRef.current = true;
            }
        } else {
            if (prevListeningRef.current == true && !channelClosed) {
                console.log('Restarting listening because isPlaying changed to false');
                startListening();
            }
        }

        // Update refs
        prevIsPlayingRef.current = isPlaying;
    }, [isPlaying, listening, startListening, stopListening, channelClosed]);


    const togglePlay = () => {
        if (!isPlaying)
            setIsTTSOn(!isTTSon);
    };
    const windowHeight = useWindowHeight();
    const marginBottomStyle = calculateMarginBottom(windowHeight);
    // console.log('useMessageInputContext', useMessageInputContext())
    useEffect(() => {
        const newWords = transcript.substring(lastTranscript.length);
        setEditableTranscript((prev) => prev + newWords);
        setLastTranscript(transcript);
        console.log('transcript', transcript, 'lastTranscript', lastTranscript)
    }, [transcript, lastTranscript]);
    useEffect(() => {
        console.log('editableTranscript', editableTranscript)
    }, [editableTranscript])
    const customSendMessage = async (messageObject) => {
        messageObject.preventDefault();
        messageObject.text = editableTranscript;
        setIsSending(true);
        console.log('messageObject', messageObject)

        await sendMessage(messageObject);

        setIsSending(false);
        resetTranscript();
        setLastTranscript('');
        setEditableTranscript('');
    };
    return (

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: marginBottomStyle }}>
            <div
                className={`mic-circle ${listening ? 'listening' : ''}`}
                onClick={toggleRecording}
                style={{ pointerEvents: isSending ? 'none' : 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <FaMicrophone
                    className={`fa-microphone ${listening ? 'listening' : ''}`}
                    style={{ fontSize: 30, margin: 'auto', color: 'peru' }}
                />
            </div>
            {<div onClick={togglePlay} style={{ marginLeft: 17, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                {isTTSon || isPlaying ?
                    <HiOutlineSpeakerWave className={`hi-speaker`}
                        style={{ fontSize: 35, margin: 'auto', color: 'peru' }} /> :
                    <HiOutlineSpeakerXMark className={`hi-speaker-off`}
                        style={{ fontSize: 35, margin: 'auto', color: 'peru' }} />
                }
            </div>}
            <div className='str-chat__message-input'>
                <div className='str-chat__message-input-inner'>
                    <div className='str-chat__message-textarea-container'>
                        <div className='str-chat__message-textarea-with-emoji-picker'>
                            <ChatAutoComplete
                                placeholder={placeholder}
                                onChange={(e) => setEditableTranscript(e.target.value)}
                                value={editableTranscript}
                                handleSubmit={customSendMessage}
                            />
                        </div>

                    </div>
                    <SendButton disabled={isSendingQuestion || editableTranscript.trim() == ''} sendMessage={customSendMessage} />

                </div>

            </div>

        </div>

    );
};

// export const FakeMessageInput = (props) => {
//     const { t } = useTranslationContext();
//     const windowHeight = useWindowHeight();
//     const marginBottomStyle = calculateMarginBottom(windowHeight);

//     return (

//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: marginBottomStyle }}>
//             <div
//                 className={`mic-circle`}
//                 style={{ pointerEvents: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//             >
//                 <FaMicrophone
//                     className={`fa-microphone`}
//                     data-tour="microphone"
//                     style={{ fontSize: 30, margin: 'auto', color: 'peru' }}
//                 />
//             </div>
//             <div style={{ marginLeft: 17, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

//                 <HiOutlineSpeakerWave className={`hi-speaker`} data-tour="speaker"
//                     style={{ fontSize: 35, margin: 'auto', color: 'peru' }} />

//             </div>
//             <div className='str-chat__message-input' >
//                 <div className='str-chat__message-input-inner' data-tour='custom-message-input'>
//                     <div className='str-chat__message-textarea-container'>
//                         <div className='str-chat__message-textarea-with-emoji-picker'>
//                             <ChatAutoComplete
//                                 placeholder={t('Type your message or start speaking...')}
//                                 disabled={true}
//                             />
//                         </div>

//                     </div>
//                     <SendButton />
//                 </div>
//             </div>

//         </div>

//     );
// };
const SurveyFeedback = ({ mode }) => {
    // console.log('mode', mode)
    const { userData } = useAuth();
    const { t, userLanguage } = useTranslationContext();
    const { channel } = useChannelStateContext();
    const isModerator = userData && userData.role === 'moderator' ? true : false;
    const { surveyID } = useParams();
    const [uniqueId, setUniqueId] = useState(generateUniqueId(5));
    let focusGroupId = null;
    if (userData) {
        focusGroupId = surveyID + '-' + userData.userID;
    }
    // const [uniqueId, setUniqueId] = useState(generateUniqueId(5));

    const { client } = useChatContext();
    const chatClient = client;
    const [channelReady, setChannelReady] = useState(false);
    const [channelClosed, setChannelClosed] = useState(false);
    const [channelSummaryData, setChannelSummaryData] = useState(null);
    const [wordCountOpen, setWordCountOpen] = useState(true);
    const [isSendingQuestion, setIsSendingQuestion] = useState(false);


    const [sessionStarted, setSessionStarted] = useState(null);
    const [showMessageInput, setShowMessageInput] = useState(false);

    const [countdown, setCountdown] = useState(5);
    const navigate = useNavigate();

    const [isTourOpen, setIsTourOpen] = useState(false);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= 767;
    const steps = [
        {
            selector: '',
            content: (
                <div style={{ color: 'black' }}>
                    <div className='text-center'>
                        <h3>{t('Welcome to the survey!')}</h3>
                    </div>
                    <p>{t('Click the')} <FaArrowRightLong style={{ marginBottom: 3 }} /> {t('to proceed with the instructions. Or click the X in the top right to exit.')}</p>
                </div>
            ),
            style: {
                maxWidth: 500
            },
        },
        {
            selector: '[data-tour="start-session"]',
            content: () => (
                <div>
                    <p>{t('Click here to start the survey.')}</p>
                    <p><strong>{t('Note:')}</strong> {t('Once you begin the survey, you must answer each question to advance to the next.')}</p>
                </div>
            ),
        },
        {
            selector: '[data-tour="leave-session"]',
            content: () => (
                <div>
                    <p>{t('Click here to leave the survey.')}</p>
                </div>
            ),
        },
        {
            selector: '',
            content: ({ close }) => (
                <div class='text-center'>
                    <p>{t('Once you complete the survey, this button will appear on top of the page.')}</p>
                    <Button style={{ marginBottom: 10 }} variant='outline-warning' size={isMobile ? 'sm' : 'lg'}>
                        {t('Submit Survey')}
                    </Button>
                    <br></br>
                    <p><strong>{t('Note:')}</strong> {t('You')} <strong><u>{t('CANNOT')}</u></strong> {t('submit the survey until all questions are answered.')}</p>
                </div>
            ),
            style: {
                maxWidth: 600
            },
        },
        {
            selector: '[data-tour="custom-message-input"]',
            content: () => (
                <div>
                    <p>{t('You can reply directly to the system by typing or speaking. You can click')} <IoMdSend style={{ marginBottom: 3 }} /> {t('to send or press ENTER')}</p>
                </div>
            ),
        },
        {
            selector: '[data-tour="microphone"]',
            content: () => (
                <div>
                    <p>{t('Click the icon here to activate speech recognition.')}</p>
                    <p>{t('A popup may appear to ask for your permission. You must allow permission to use your microphone in order to use this feature.')}</p>
                    {!isMobile && <div className='text-center'><img src={microphone_permission_img} alt={t("Microphone Permission")}></img></div>}
                    <p><strong>{t('Note:')}</strong> {t('This feature is only available for compatible browsers. Safari, Google Chrome and Firefox are highly recommended.')}</p>
                </div>
            ),
            style: {
                maxWidth: isMobile ? 400 : 700
            },
        },
        {
            selector: '[data-tour="speaker"]',
            content: () => (
                <div>
                    <p>{t('You can click the icon here to turn on the speech-out feature On/Off.')}</p>
                    {/* <p><strong>{t('Note:')}</strong> {t('Speech out is available in English only.')}</p> */}
                </div>
            ),
        },
    ];



    const [isPlaying, setIsPlaying] = useState(false);
    const [isTTSon, setIsTTSOn] = useState(false);
    const [completed, setCompleted] = useState(false);
    useEffect(() => {
        const initChat = async () => {
            if (!userData || !userData.userID || !userData.token) {
                console.error('Invalid user object. Missing user ID or Stream Chat token.');
                return;
            }

            try {
                const channel = client.channel('survey', `${focusGroupId}`, {
                    name: 'Survey',
                    // image: "https://i.ibb.co/vhzRfqm/logo.png",
                    // open: true,
                });
                await channel.watch();
                const channel_role = 'channel_moderator'
                await channel.addMembers([{ user_id: userData.userID, channel_role: channel_role }]);
                console.log('added members', `${focusGroupId}`, [userData.userID], channel_role)
                setChannelReady(true);

            } catch (error) {
                console.error('Error initializing chat:', error.message);
            }
        };

        if (userData && !channelReady && focusGroupId) {
            console.log('Initializing chat...');
            initChat();
        }


        // Clean up the event listener when the component unmounts
        return () => {

        };
    }, [userData, focusGroupId, channelReady, chatClient]);
    useEffect(() => {
        console.log('chatClient', chatClient)
    }, [chatClient])

    //useeffect for userdata if valid set uniqueId to userData.userID.substring(5)
    // useEffect(() => {
    //     if (userData) {
    //         const uID = userData.userID.substring(0, 5)
    //         setUniqueId(uID)
    //         console.log('')
    //         if (userData['completed_surveys'] && userData['completed_surveys'][surveyID]) {
    //             setCompleted(true);
    //             console.log('setCompleted true')
    //         }
    //     }
    // }, [userData])

    // useEffect(() => {
    //     console.log('uniqueId', uniqueId)
    // }, [uniqueId])
    useEffect(() => {
        if (mode === 'chat') {
            console.log('sessionStarted', sessionStarted)
            setShowMessageInput(sessionStarted)
            if (sessionStarted)
                setIsTourOpen(false);
            else if (sessionStarted == false)
                setIsTourOpen(true);

        }
        else {
            setShowMessageInput(false)
        }
    }, [sessionStarted, userData])
    //Create use effect for channelSummaryData
    useEffect(() => {
        if (channelSummaryData) {
            console.log('channelSummaryData', channelSummaryData)
        }
    }, [channelSummaryData, channelClosed])

    useEffect(() => {
        // Start the countdown when the channel is closed
        if (channelClosed) {
            const timerId = setInterval(() => {
                setCountdown((currentCountdown) => {
                    if (currentCountdown <= 1) {
                        clearInterval(timerId);
                        navigate('/qrcode?confirmation=' + uniqueId);
                        return 0;
                    }
                    return currentCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timerId);
        }
    }, [channelClosed, userData, channelSummaryData, channel, completed]);

    return <>
        {!channelClosed ? <div style={{
            height: '99.5vh',
            overflow: 'hidden',
            paddingTop: 10,
            // paddingBottom: 5,
            border: mode == 'chat' ? 'none' : '4px dashed var(--primaryred)',
            borderRadius: 10,
        }}>
            {channelReady ? (
                <Channel
                    channel={chatClient.channel('survey', focusGroupId)}
                    Input={CustomMessageInput}

                >
                    <Window>
                        {<CustomChannelHeader
                            userData={userData}
                            focusGroupId={focusGroupId}
                            surveyID={surveyID}
                            setParentSessionStarted={setSessionStarted}
                            setChannelClosed={setChannelClosed}
                            setChannelSummaryData={setChannelSummaryData}
                            channelClosed={channelClosed}
                            mode={mode}
                            uniqueId={uniqueId}
                            isTourOpen={isTourOpen}
                            isTTSon={isTTSon}
                            setIsTTSOn={setIsTTSOn}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                            setShowMessageInput={setShowMessageInput}
                            isSendingQuestion={isSendingQuestion}
                            setIsSendingQuestion={setIsSendingQuestion}
                        />}
                        <MessageList messageActions={null} />
                        {/* {showMessageInput ? (
                                <CustomMessageInput
                                    isTTSon={isTTSon}
                                    setIsTTSOn={setIsTTSOn}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                />
                            ) : <></>} */}
                        {/* {isTourOpen && <FakeMessageInput />} */}
                        {/* <FakeMessageInput /> */}
                        {showMessageInput && <MessageInput
                            // disabled={isSendingQuestion}
                            additionalTextareaProps={{
                                isTTSon,
                                setIsTTSOn,
                                isPlaying,
                                setIsPlaying,
                                channelClosed,
                                placeholder: isSendingQuestion ? t('Question is sending...') : t('Type your message or start speaking...'),
                                isSendingQuestion
                            }}

                            grow={true} />}
                        {mode == 'observe' && <div className='text-center my-3'>
                            <h4 className='blink-text'>
                                <strong><FaGlasses style={{ marginBottom: 5 }} /> You are observing...</strong></h4>
                        </div>}
                    </Window>
                </Channel>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '90vh',
                    }}
                >
                    {/* <LoadingIndicator size={100} /> */}
                </div>
            )}
            <Tour
                steps={steps}
                accentColor="peru"
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />
        </div> : <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '93vh',
                width: '90%', // Adjust this value to change the width
                maxWidth: '1200px', // Optional: Sets a maximum width
                margin: 'auto', // Centers the div on the page
            }}
        >
            {/* <LoadingIndicator size={100} /> */}
            <img src={unavailableImg} height={150}></img>
            <div style={{ textAlign: 'center', marginTop: 10, width: '50%' }}> {/* Add some space between the indicator and the text */}
                {/* <h4 style={{ marginBottom: 0 }}><b>The session has been closed.</b></h4> */}
                <h5>{t('Thank you for participating in the survey!')}</h5>
                {/* <h6>Please join the next available session.</h6> */}

                {/* <Button style={{ marginTop: 10 }} variant='outline-secondary' onClick={() => { window.location.href = 'https://forms.office.com/Pages/ResponsePage.aspx?id=vv3-4-n3G0ClGjVeAbBaiWmees9sbzlBl8X5tilVzk1UQ1hOSzVTT0NSVk80TlVJNkFHWVRQM0NZTy4u'; }}>{t('Go Back')}</Button> */}
                {/* <Button onClick={() => { window.location.reload() }}>Refresh</Button> */}
                <h6>{t('You will be automatically redirected to the HDF Scholars Prize Drawing page in') + ' '} <strong style={{ fontSize: 20 }}>{countdown}</strong> {t('second(s)')} ...</h6>
            </div>

        </div>

        }
    </>


};

export default SurveyFeedback;

const Timer = ({ sessionStarted, sessionStartTime }) => {
    const [time, setTime] = useState(null);

    useEffect(() => {
        let interval;

        if (sessionStarted && sessionStartTime) {
            // console.log('sessionStartTime', sessionStartTime)
            const startTime = new Date(sessionStartTime).getTime();

            interval = setInterval(() => {
                const currentTime = new Date().getTime();
                const elapsedTime = Math.floor((currentTime - startTime) / 1000);
                setTime(elapsedTime);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [sessionStarted, sessionStartTime]);

    return <span>
        {sessionStarted && time && <strong style={{ color: 'peru' }}>{formatTime(time)}</strong>}
    </span>
};

const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedHours = hours > 0 ? `${hours}:` : '';
    const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}:`;
    const formattedSeconds = `${seconds < 10 ? '0' : ''}${seconds}`;

    return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
};

export const formatTimeDifference = (startTime, endTime) => {
    const start = new Date(startTime).getTime();
    const end = new Date(endTime).getTime();

    // Calculate difference in seconds
    const timeDifference = Math.floor((end - start) / 1000);

    // Formatting time
    const hours = Math.floor(timeDifference / 3600);
    const minutes = Math.floor((timeDifference % 3600) / 60);
    const seconds = timeDifference % 60;

    const formattedHours = hours > 0 ? `${hours}:` : '';
    const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}:`;
    const formattedSeconds = `${seconds < 10 ? '0' : ''}${seconds}`;

    return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
};

function calculateMarginBottom(windowHeight) {
    if (windowHeight <= 700) {
        return '5vh';
    } else if (windowHeight > 700 && windowHeight <= 800) {
        return '3vh';
    } else {
        return 5;
    }
}