import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, query, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
//import { formatTimeDifference } from './ChatPage';
import welisten_logo from "../Assets/images/welisten_logo_new_transparent.png";
import unitedway from "../Assets/images/unitedway.png";

//import { ErrorPage, LoadingPage, QRErrorPage } from './ErrorPage';
import QRCode from 'qrcode.react';
import { useChatContext } from 'stream-chat-react';
import FlipNumbers from 'react-flip-numbers';
const flipNumberHeight = 40;
const flipNumberWidth = 24; // Adjust width as needed for your numbers
const Monitor = () => {
    // const { client } = useChatContext();
    // use firestore onSnapShot to get collection survey_channels and save it to state
    const [surveyChannels, setSurveyChannels] = useState([]);
    const [surveyStats, setSurveyStats] = useState(null);//{averageWordCount, numberOfUsers, numberOfCompletedUsers}


    useEffect(() => {
        const surveyChannelDocRef = collection(firestore, 'focus_group_messages');
        const q = query(surveyChannelDocRef);

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const channels = [];
            querySnapshot.forEach((doc) => {
                channels.push({ id: doc.id, ...doc.data() });
            });
            setSurveyChannels(channels);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        console.log('surveyChannels', surveyChannels)
        if (surveyChannels.length > 0) {
            const aggregatedData = aggregateSurveyData(surveyChannels);
            console.log('aggregatedData', aggregatedData);
            setSurveyStats(aggregatedData);
        }
    }, [surveyChannels]);



    const navigate = useNavigate();
    // if (surveyStats === undefined) {
    //     return <ErrorPage />;
    // }

    // Check if surveyStats is null, which means the data is still loading
    // if (surveyStats === null) {
    //     return <LoadingPage />;
    // }
    return (surveyStats && <Container className='text-center' style={{ marginTop: 100 }}>
        <Card className="mb-3 text-center" style={{ borderColor: 'lightgray' }}>
            <Card.Img variant="top" src={welisten_logo} style={{ width: 250, margin: 'auto', paddingTop: '20px' }} />

            <Card.Body>
                <Card.Title><h2>Session Statistics</h2></Card.Title>
                <Card.Text as="div">
                    <Row className='align-items-center my-3'>
                        <Col xs={6} className="text-start"><h5>Total Number of Users:</h5></Col>
                        <Col xs={6} className="text-end">
                            <FlipNumbers
                                duration={3}
                                height={flipNumberHeight}
                                width={flipNumberWidth}
                                color="var(--primary)"
                                background="transparent"
                                play
                                numbers={surveyStats.numberOfUsers.toString()}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-3'>
                        <Col xs={6} className="text-start"><h5>Avarage Number of Users/Session:</h5></Col>
                        <Col xs={6} className="text-end">
                            <FlipNumbers
                                duration={3}
                                height={flipNumberHeight}
                                width={flipNumberWidth}
                                color="var(--primary)"
                                background="transparent"
                                play
                                numbers={surveyStats.averageNumberOfUsers.toString()}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-3'>
                        <Col xs={6} className="text-start"><h5>Average Word Count:</h5></Col>
                        <Col xs={6} className="text-end">
                            <FlipNumbers
                                duration={2.5}
                                height={flipNumberHeight}
                                width={flipNumberWidth}
                                color="var(--primary)"
                                background="transparent"
                                play
                                numbers={Math.round(surveyStats.averageWordCount).toString()}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-3'>
                        <Col xs={6} className="text-start"><h5>Average Session Duration:</h5></Col>
                        <Col xs={6} className="text-center">
                            <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                                {/* <Digit digit={2} unit="Day" /> */}
                                {surveyStats.averageSessionDuration.hours !== 0 && <Digit digit={surveyStats.averageSessionDuration.hours} unit="Hours" />}
                                <Digit digit={surveyStats.averageSessionDuration.minutes} unit="Minutes" />
                                <Digit digit={surveyStats.averageSessionDuration.seconds} unit="Seconds" />
                            </div>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>

        </Card>
        <Button style={{ marginTop: 10 }} variant='outline-secondary' onClick={() => navigate('/')}>Go Back</Button>
    </Container>)
}
export default Monitor;
const Digit = ({ digit, unit }) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 5px",
        }}
    >
        <div
            style={{
                backgroundColor: "var(--primary)",
                borderRadius: 10,
                padding: "5px 10px",
                marginBottom: 5,
            }}
        >
            {/* {digit} */}
            <FlipNumbers
                play
                duration={1}
                background="var(--primary)"
                color="#fff"
                width={flipNumberWidth}
                height={35}
                numbers={digit <= 9 ? `0${digit}` : `${digit}`}
            />
        </div>
        <div className='text-primary'><b>{unit.toUpperCase()}</b></div>
    </div>
);

function aggregateSurveyData(documents) {
    let totalWordCount = 0;
    let totalDurationSeconds = 0; // Store total duration in seconds
    let numberOfUsers = 0;
    let averageNumberOfUsers = 0;
    documents.forEach(doc => {
        if (doc.data) {
            console.log('doc.data', doc.data)
            const { members, started_at, last_message_at, userStats } = doc.data;

            // Update the number of users based on the length of the members list
            if (members && members.length > 0)
                numberOfUsers += members.length;

            // Calculate the total duration of the sessions
            if (started_at && last_message_at) {
                const startTime = new Date(started_at);
                const endTime = new Date(last_message_at);
                const duration = (endTime - startTime) / 1000; // Convert milliseconds to seconds
                totalDurationSeconds += duration;
            }
            // Aggregate word counts
            for (const userID in userStats) {
                // Exclude 'united_way_assistant' word count
                if (userID !== 'united_way_assistant') {
                    totalWordCount += userStats[userID].wordCount || 0;
                }
            }
        }
    });
    averageNumberOfUsers = Number((numberOfUsers / documents.length).toFixed(2)); //round this up
    averageNumberOfUsers = Math.round(averageNumberOfUsers);
    const averageWordCount = totalWordCount / numberOfUsers;
    const averageDurationSeconds = totalDurationSeconds / numberOfUsers;

    // Calculate average duration in hours, minutes, and seconds
    const averageDuration = {
        hours: Math.floor(averageDurationSeconds / 3600),
        minutes: Math.floor((averageDurationSeconds % 3600) / 60),
        seconds: Math.floor(averageDurationSeconds % 60),
    };

    return {
        averageWordCount,
        numberOfUsers,
        averageNumberOfUsers,
        averageSessionDuration: {
            hours: averageDuration.hours,
            minutes: averageDuration.minutes,
            seconds: averageDuration.seconds
        },
    };
}

