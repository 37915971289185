// QrCodePage.js
import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';
import useAuth from '../../Authentication/useAuth';

const QrCodePage = () => {
  const {userData} = useAuth();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>QR Code Page</h1>
      {userData && (
        <div>
          {/* <p>User ID: {userData.userID}</p>
          <p>Email: {userData.email}</p> */}
          {/* <p>Word Count: {userData.wordCount}</p> */}
          {/* <p>First Name: {userData.firstName}</p>
          <p>Last Name: {userData.lastName}</p> */}
          <QRCode
            value = {`User ID: ${userData.userID}, 
            Email: ${userData.email}, 
            First name: ${userData.firstName}, 
            Last name: ${userData.lastName}`}   //, ${userData.wordCount}
          />
        </div>
      )}
    </div>
  );
};

export default QrCodePage;