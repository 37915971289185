import React from 'react';
import { Navigation } from './App';
import useAuth from './Authentication/useAuth';
import { Container } from 'react-bootstrap';

function Home() {
    const { user, userData } = useAuth();
    console.log('useAuth()', user, userData);
    return (
        <Container>
            {/* <Navigation user={user} userData={userData} /> */}
            <h2>Focus Group Management: </h2>
            <p>You can create and schedule new focus group meetings. You can also add and revise questionnaires.
            </p>
            <h2>Analytics Description: </h2>
            <p>This visualization shows the output from an NLP model that imitates binary coding of content analysis. Each row represents a content feature, and the chart shows the probability of each data point (e.g., a comment) containing the content feature.
                Unlike human binary decision (0=not having the feature, 1=having the feature), this AI support provides a probability score between 0 and 1. The human analyst can decide the cutting-off threshold and the optimal model depending upon a desired confidence level.
            </p>
        </Container>
    );
}

export default Home;
