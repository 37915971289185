
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { getAuth, signOut } from 'firebase/auth';
import useAuth from './Authentication/useAuth'; // Assuming this is at the same level as App.js

// Importing pages from the analytics folder
import SignIn from './Authentication/SignIn';
import Page1 from './Admin/Page1';
import Page2 from './Admin/Page2';
import Page3 from './Admin/Page3';
import Page4 from './Admin/Page4';
import Page5 from './Admin/Page5';
import Home from './Home'
import Graph from './Admin/analytics/pages/graph';
import Wordcloud from './Admin/analytics/pages/wordcloud';
import Network from './Admin/analytics/pages/network';
import GraphChanges from './Admin/analytics/pages/graphchanges';
import './App.css';
import HomePage from './Participants/Home/HomePage';
//import Login from './Participants/Auth/Login';
import Signup from './Participants/Auth/Signup';
import FocusGroups from './Participants/Home/FocusGroups';
// import FocusGroupDetails from './Participants/Home/FocusGroupDetails';
import ChatPage from './Participants/Home/ChatPage';
import QrCodePage from './Participants/Home/QrCodePage';
import SurveyFeedback from './Participants/Home/SurveyFeedback';
//import Index from './Participants/Index';
import { Chat } from 'stream-chat-react';
import logo from './Assets/images/welisten_logo_new_transparent.png'
import QRCodeConfirmationPage from './Participants/Home/QRCodeConfirmationPage';
import { ErrorPage, LoadingPage } from './Participants/Home/ErrorPage';
function LogoutButton({ }) {
  const navigate = useNavigate(); // Move useNavigate inside the component
  // console.log('user', user)
  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log('Signed out');
      navigate('/'); // Navigate to the home page or login page after logout
      window.location.reload();
    } catch (error) {
      console.error('Error signing out: ', error);
      // Handle any errors that occur during sign out
    }
  };

  return <Button onClick={handleLogout}>Sign Out</Button>;
}
export function Navigation({ user, userData }) {
  let isAdmin = 'user';
  if (userData)
    isAdmin = userData.role === 'admin'
  return (
    user && <Navbar className="custom-navbar" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          {/* WeListen Dashboard {isAdmin && "(Admin)"}
           */}
          <img
            src={logo}
            style={{
              width: 130,
              height: 40,
              paddingBottom: 5
            }}
          />

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {isAdmin ? (
              <>
                <Nav.Link as={Link} to="/page1">Schedule Meeting</Nav.Link>
                <Nav.Link as={Link} to="/page2">Focus Group Details</Nav.Link>
                <Nav.Link as={Link} to="/page3">Add Questions</Nav.Link>
                <Nav.Link as={Link} to="/page4">Download Data</Nav.Link>
                <Nav.Link as={Link} to="/page5">Statistics</Nav.Link>
                <NavDropdown title="Analytics" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/graph">Graph Visualization</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/wordcloud">Word Cloud</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/network">Network Visualization</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/graphchanges">Graph Changes</NavDropdown.Item>
                </NavDropdown>
                <LogoutButton />
              </>
            ) : (
              <>
                <LogoutButton />
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

function App() {
  const { user, userData, chatClient } = useAuth();
  console.log('useAuth()', user, userData, chatClient)
  let isAdmin = false;
  if (userData)
    isAdmin = userData.role === 'admin';
  // Check if the current path is /QRCode
  const pathname = window.location.pathname;
  if (pathname.toLowerCase() === '/qrcode') {
    return <div className="App">
      <QRCodeConfirmationPage />
    </div>;
  }
  // if (chatClient === undefined) {
  //   return <div className="App" >
  //     <SignIn />
  //   </div>
  // }
  if (chatClient === null || user === null) {
    return <div className="App" >
      <LoadingPage />
    </div>
  }
  return (
    <div className="App" >
      <div style={{ padding: 1 }}>
        {user && chatClient ?
          (
            isAdmin ? (
              // Routes for Admins
              <div>
                <Navigation user={user} userData={userData} />
                <div style={{ marginTop: 90, padding: 1 }}>
                  <Routes>
                    <Route path="/" element={<Home user={user} userData={userData} />} />
                    <Route path="/page1" element={<Page1 user={user} userData={userData} />} />
                    <Route path="/page2" element={<Page2 user={user} userData={userData} />} />
                    <Route path="/page3" element={<Page3 user={user} userData={userData} />} />
                    <Route path="/page4" element={<Page4 user={user} userData={userData} />} />
                    <Route path="/page5" element={<Page5 user={user} userData={userData} />} />
                    <Route path="/graph" element={<Graph />} />
                    <Route path="/wordcloud" element={<Wordcloud />} />
                    <Route path="/network" element={<Network />} />
                    <Route path="/graphchanges" element={<GraphChanges />} />

                  </Routes>
                </div>

              </div>

            ) : (
              <Chat client={chatClient}>
                <Routes>
                  <Route path="/" element={<HomePage user={user} userData={userData} chatClient={chatClient} />} />
                  <Route path="/signup" element={<HomePage userData={userData} />} />
                  <Route path="/home" element={<HomePage userData={userData} />} />
                  <Route path="/focus-groups/:title" element={<FocusGroups />} />
                  <Route path="/focus-groups/:focusGroupId/chat" element={<ChatPage mode='chat' />} />
                  <Route path="/focus-groups/:focusGroupId/observe" element={<ChatPage mode='observe' />} />
                  <Route path="/qr-code/:focusGroupId" element={<QrCodePage />} />
                  <Route path="/qr-code" element={<QrCodePage />} />
                  <Route path="/QRCode" element={<QRCodeConfirmationPage />} />
                  <Route path="survey/usability_test/chat" element={<SurveyFeedback/>} />
                </Routes>
              </Chat>
            ))

          : ( //Routes for not logged in
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/QRCode" element={<QRCodeConfirmationPage />} />
              <Route path="*" element={<SignIn />} />
            </Routes>
          )}
      </div>
    </div>
  );
}

export default App;