import React, { useEffect, useState } from 'react';
import { firestore } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import { formatTimeDifference } from './ChatPage';
import welisten_logo from "../../Assets/images/welisten_logo_new_transparent.png";
import { ErrorPage, LoadingPage, LoadingPageGrid, QRErrorPage } from './ErrorPage';
import { BallTriangle, Grid } from 'react-loading-icons';
import QRCode from 'qrcode.react';
import logo from '../../Assets/images/welisten_logo_new_transparent.png'


const QRCodeConfirmationPage = () => {
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--bluedark').trim();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentDomain = window.location.origin;
    const confirmationId = queryParams.get('confirmation');
    const qrCodeValue = `${currentDomain}/QRCode?confirmation=${confirmationId}`;
    const [qrData, setQrData] = useState(null);
    // const [isModerator, setIsModerator] = useState(false); // Assuming you have a way to determine if the user is a moderator or not
    let isModerator = false;
    const handlePrint = () => {
        window.print();
    };
    useEffect(() => {

        if (confirmationId) {
            const qrDocRef = doc(firestore, "QR_Codes", confirmationId);
            getDoc(qrDocRef)
                .then(docSnapshot => {
                    if (docSnapshot.exists()) {
                        const data = docSnapshot.data().data
                        setQrData(data); // Assuming the structure includes a 'data' field
                        // setQrData(null)
                        // setQrCodeValue(`${currentDomain}/QRCode?confirmation=${data.}`)
                    } else {
                        console.log("No such document!");
                        setQrData(undefined);
                    }
                })
                .catch(error => {
                    console.error("Error getting document:", error)
                    setQrData(undefined);
                });
        }
    }, [location.search]);
    // useEffect(() => {

    //     if (qrData && qrData.userData) {
    //         setIsModerator(qrData.userData.role === 'moderator');
    //     }
    // }, [qrData])

    if (qrData === undefined) return <QRErrorPage />

    // if (qrData === null) return <LoadingPage />
    if (qrData === null) return <LoadingPage />
    if (qrData && qrData.userData) {
        isModerator = qrData.userData.role === 'moderator';
    }
    console.log('qrData', qrData)

    const sessionDuration = formatTimeDifference(qrData.started_at, qrData.ended_at);

    return (
        <Container className='mt-5'>
            {qrData && <Card className="mb-3 text-center" style={{ borderColor: 'lightgray' }}>
                <Card.Img variant="top" src={welisten_logo} style={{ width: 250, margin: 'auto', paddingTop: '20px' }} />
                <Card.Body>
                    <Card.Title>Session Information</Card.Title>
                    <Card.Subtitle>
                        User: <b>{qrData && qrData.userData && qrData.userData.nickname ? qrData.userData.nickname : 'N/A'}</b> {isModerator ? "(Moderator)" : "(Participant)"}
                    </Card.Subtitle>
                    <Card.Text as="div">
                        <Row>
                            <Col xs={6} className="text-start">Session Start Time:</Col>
                            <Col xs={6} className="text-end">
                                <strong>{new Date(qrData.started_at).toLocaleString()}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-start">Session End Time:</Col>
                            <Col xs={6} className="text-end">
                                <strong>{new Date(qrData.ended_at).toLocaleString()}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-start">Number of participants:</Col>
                            <Col xs={6} className="text-end">
                                <strong>{Object.keys(qrData.members).length}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="text-start">Session Duration:</Col>
                            <Col xs={6} className="text-end">
                                <strong>{sessionDuration}</strong>
                            </Col>
                        </Row>
                        <Card.Title>

                            Word Count Summary
                        </Card.Title>

                        {isModerator ? qrData.userStats && Object.entries(qrData.userStats).map(([userID, userStat]) => (
                            <Row key={userID}>
                                <Col xs={6} className="text-start">
                                    {userStat.user.nickname} {userID === qrData.userData.userID && "(You)"}:
                                </Col>
                                <Col xs={6} className="text-end">
                                    <strong>{userStat.wordCount}</strong> word(s)
                                </Col>
                            </Row>
                        )) : <div>
                            <Row>
                                <Col xs={6} className="text-start">{qrData.userData.nickname} (You):
                                </Col>
                                <Col xs={6} className="text-end">
                                    <strong>{qrData.userStats[qrData.userData.userID] && qrData.userStats[qrData.userData.userID].wordCount ? qrData.userStats[qrData.userData.userID].wordCount : 0}</strong> word(s)
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} className="text-start">Others:</Col>
                                <Col xs={6} className="text-end">
                                    <strong>
                                        {qrData.userStats
                                            ? Object.values(qrData.userStats).reduce((sum, stats) => sum + stats.wordCount, 0)
                                            - (qrData.userStats[qrData.userData.userID] ? qrData.userStats[qrData.userData.userID].wordCount : 0)
                                            : 0}
                                    </strong> word(s)
                                </Col>
                            </Row>
                        </div>}
                        <br></br>

                        {qrData.userStats &&
                            <Row>
                                <Col xs={6} className="text-start">Total Word Count:</Col>
                                <Col xs={6} className="text-end">
                                    <strong>{Object.values(qrData.userStats).reduce((sum, stats) => sum + stats.wordCount, 0)}</strong> word(s)
                                </Col>
                            </Row>
                        }
                    </Card.Text>

                    <QRCode value={qrCodeValue}
                        size={130}
                        level={'M'}
                        fgColor={primaryColor}
                        imageSettings={{
                            src: logo,
                            x: null,
                            y: null,
                            height: 27,
                            width: 40,
                            excavate: true,
                        }}
                    />
                    <div className='text-muted'>Confirmation #: {confirmationId}</div>
                    <Button style={{ marginTop: 10 }} variant='outline-secondary' onClick={() => { window.location.href = '/'; }}>Go To Portal</Button>


                </Card.Body>

                <Card.Footer className='no-print'>
                    <Card.Text>Note: please show this code to the event organizer for further information. <span className="no-print">
                        Click <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handlePrint}>here</span> to print or export to PDF file.
                    </span></Card.Text>
                </Card.Footer>
            </Card>}

        </Container>

    );
};

export default QRCodeConfirmationPage;


