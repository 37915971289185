import "./styles.css";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "Q1",
    Topic1: -4000,
    Topic2: 2400,
    Topic3: 2400
  },
  {
    name: "Q2",
    Topic1: 3000,
    Topic2: 1398,
    Topic3: 2210
  },
  {
    name: "Q3",
    Topic1: -2000,
    Topic2: 9800,
    Topic3: 2290
  },
  {
    name: "Q4",
    Topic1: 2780,
    Topic2: 3908,
    Topic3: 2000
  },
  {
    name: "Q5",
    Topic1:- 1890,
    Topic2: 4800,
    Topic3: 2181
  },
  {
    name: "Q6",
    Topic1: 2390,
    Topic2: 3800,
    Topic3: 2500
  },
  {
    name: "Q7",
    Topic1: 3490,
    Topic2: 4300,
    Topic3: 2100
  }
];

function GraphChanges() {
	
  return (
<div class="container d-flex h-100">
<div class="row align-self-center w-100">
<div class="col-6 mx-auto">
		<LineChart
		  width={600}
		  height={500}
		  data={data}
		  margin={{
			top: 100,
			right: 30,
			left: 20,
			bottom: 5
		  }}
		>
		  <XAxis dataKey="name" />
		  <YAxis />
		  <Tooltip />
		  <Legend />
		  <Line dataKey="Topic2" stroke="#8884d8" activeDot={{ r: 8 }} strokeWidth={3}
		  />
		  <Line dataKey="Topic1" stroke="#82ca9d" strokeWidth={3} />
		  <Line dataKey="Topic3" stroke="black" strokeWidth={3}/>
		  
		</LineChart>
</div>
</div>
</div>

  );
}
export default GraphChanges;