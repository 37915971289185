import React, { useEffect, useState } from 'react';
import { firestore, auth } from '../../firebase';
import QRCode from 'qrcode.react';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { Grid } from 'react-loading-icons';
import { LoadingPageGrid } from './ErrorPage';

const QRCodeGenerator = ({ channelSummaryData = null, targetUserID = null, userData = null, uniqueId = null }) => {
    const [qrId, setQrId] = useState('');
    useEffect(() => {
        if (channelSummaryData && targetUserID && channelSummaryData.userStats) {
            const userStats = channelSummaryData.userStats[targetUserID];
            const qrData = { ...userStats, ...channelSummaryData, userData }; // Adjust according to your data structure

            // Store in Firebase
            // const qrCodesRef = collection(firestore, "QR_Codes");
            const qrCodesRef = doc(firestore, 'QR_Codes', uniqueId);

            setDoc(qrCodesRef, { id: uniqueId, data: qrData })
                .then(() => {
                    setQrId(uniqueId);
                })
                .catch(error => console.error("Error adding document: ", error));
        }
    }, [channelSummaryData, targetUserID, userData]);


    if (!qrId) return <LoadingPageGrid />
    // Use window.location.origin to get the current domain
    const currentDomain = window.location.origin;
    const qrCodeValue = `${currentDomain}/QRCode?confirmation=${qrId}`;

    return (
        <div>
            <LoadingPageGrid />
            {/* <QRCode value={qrCodeValue} /> */}
            {/* <div className='text-muted'>Confirmation #: {qrId}</div> */}
        </div>
    );
};

export default QRCodeGenerator;
// Function to generate a unique ID
export function generateUniqueId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
