import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import './pages.css';
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Row,
  Col,
  Container
} from "reactstrap";


function Page2() {
  const [focusGroups, setFocusGroups] = useState([]);
  const [filterGroupId, setFilterGroupId] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [selectedTiles, setSelectedTiles] = useState([]);

  useEffect(() => {
    const fetchFocusGroups = async () => {
      try {
        const focusGroupsCollection = collection(firestore, 'focus_groups');
        let q = focusGroupsCollection;

        if (filterGroupId) {
          q = query(q, where('group_id', '==', filterGroupId));
        }

        if (filterStartDate) {
          const [year, month, day] = filterStartDate.split('-').map(Number);
          const startOfDay = new Date(year, month - 1, day);
          const endOfDay = new Date(year, month - 1, day, 23, 59, 59, 999);
          q = query(
            q,
            where('start_time', '>=', Timestamp.fromDate(startOfDay)),
            where('start_time', '<=', Timestamp.fromDate(endOfDay))
          );
        }

        const querySnapshot = await getDocs(q);
        const focusGroupsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          if (data.start_time instanceof Timestamp) {
            data.start_time = data.start_time.toDate();
          }
          return data;
        });

        setFocusGroups(focusGroupsData.length > 0 ? focusGroupsData : []);
      } catch (error) {
        console.error('Error fetching focus groups: ', error);
      }
    };
    fetchFocusGroups();
  }, [filterGroupId, filterStartDate]);

  const handleTileSelection = (index) => {
    if (selectedTiles.includes(index)) {
      setSelectedTiles(selectedTiles.filter((i) => i !== index));
    } else {
      setSelectedTiles([...selectedTiles, index]);
    }
  };

  const postTableToFacebook = (selectedTiles) => {
    const pageId = '133112926560832'; // Replace with your Facebook Page ID
    const facebookAccessToken = 'EAAMXw5a0PoQBOy9kmq5YLtmIZBAlXeASbyfU2b7otgm56ZBfFFbXAeEXF0WxB7sryH1MuxvoJApEgbShAe7I8BLVkY29L3wTIwZA4mLf1Qv1ma5NDnGho8IoItQTZCSsHjZBGjZCA6Te18ZBMe4dsNouMfNOcaNJJZCILqaEmQinylsfldcAVSlPjFjEuR4RJGxUK4XamDZBbHo6H98sz35G6JxXj'; // Replace with your Page Access Token

    // Construct the message with selected tiles' details
    const selectedTilesMessage = selectedTiles.map((index) => {
      const group = focusGroups[index];
      return `
        Name: ${group.name}
        Group ID: ${group.group_id}
        Max Limit: ${group.max_limit}
        Moderator: ${group.moderator}
        Start Time: ${group.start_time ? group.start_time.toLocaleString() : ''}
      `;
    });

    const message = selectedTilesMessage.join('\n\n');

    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '870553744588420', // Replace with your Facebook App ID
        xfbml: true,
        version: 'v18.0', // Facebook Graph API version
      });
    };

    // Post to Facebook Page
    window.FB.api(
      `/${pageId}/feed`,
      'POST',
      {
        message: message,
        access_token: facebookAccessToken,
      },
      function (response) {
        if (!response || response.error) {
          console.error('Error posting to Facebook Page:', response.error);
        } else {
          console.log('Post successful:', response);
        }
      }
    );
  };

  return (
    <>
      <Container>
        <h3 className="h4 text-primary font-weight-bold mb-4 text-center">Focus Group Details</h3>

        <Row className='justify-content-center'>
          <Col lg="4" sm="10">
            <FormGroup>
              <Input
                type="text"
                placeholder="Filter by Group ID"
                value={filterGroupId}
                onChange={(e) => setFilterGroupId(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg="4" sm="10">
            <FormGroup>
              <Input
                type="date"
                placeholder="Filter by Start Date"
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg="4" sm="10">
            <FormGroup>
              <Button color='primary' onClick={() => postTableToFacebook(selectedTiles)}>
                Post to Facebook
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>

          {focusGroups.map((group, index) => (
            <Col key={index} lg="6" sm="10" className="h-100">
              <FormGroup className="focus-group-tile col-lg-12 col-sm-6 border p-4 mb-4 h-100 d-flex flex-column justify-content-between">
                <span><input
                  type="checkbox"
                  checked={selectedTiles.includes(index)}
                  onChange={() => handleTileSelection(index)}
                /><h4>{group.name}</h4></span>

                <p className='mb-1'>Group ID: {group.group_id}</p>
                <p className='mb-1'>Max Limit: {group.max_limit}</p>
                <p className='mb-1'>Moderator: {group.moderator}</p>
                <p className='mb-1'>Start Time: {group.start_time ? group.start_time.toLocaleString() : ''}</p>
              </FormGroup>
            </Col>
          ))}

          {/* <Col md="6">
            <FormGroup className="has-danger">
              <Input
                className="is-invalid"
                placeholder="Error Input"
                type="email"
              />
            </FormGroup>
          </Col> */}
        </Row>

      </Container >
    </>
    // <div>
    //   <h2>Focus Group Meetings</h2>
    //   <div className="filter-options">
    //     <input
    //       type="text"
    //       placeholder="Filter by Group ID"
    //       value={filterGroupId}
    //       onChange={(e) => setFilterGroupId(e.target.value)}
    //     />
    //     <input
    //       type="date"
    //       placeholder="Filter by Start Date"
    //       value={filterStartDate}
    //       onChange={(e) => setFilterStartDate(e.target.value)}
    //     />
    //   </div>
    //   <div className="focus-group-list">
    //     <button onClick={() => postTableToFacebook(selectedTiles)}>
    //       Post to Facebook
    //     </button>
    //     {focusGroups.map((group, index) => (
    //       <div key={index} className="focus-group-tile">
    //         <input
    //           type="checkbox"
    //           checked={selectedTiles.includes(index)}
    //           onChange={() => handleTileSelection(index)}
    //         />
    //         <h3>{group.name}</h3>
    //         <p>Group ID: {group.group_id}</p>
    //         <p>Max Limit: {group.max_limit}</p>
    //         <p>Moderator: {group.moderator}</p>
    //         <p>Start Time: {group.start_time ? group.start_time.toLocaleString() : ''}</p>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
}

export default Page2;
