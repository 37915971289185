export const sampleMessages = [
    {
        "id": "moderator-start",
        "text": "Welcome everyone! Today, let's discuss the impact of social media on mental health. What are your thoughts on this issue?",
        "type": "regular",
        "user": {
            "id": "moderator1",
            "role": "moderator",
            "name": "Kelly",
            "email": "moderator@example.com"
        }
    },
    {
        "id": "user1-response",
        "text": "I think social media can be both positive and negative. It's great for staying connected, but it can also lead to feelings of inadequacy and anxiety.",
        "type": "regular",
        "user": {
            "id": "user1",
            "role": "user",
            "name": "Alice",
            "email": "alice@example.com"
        }
    },
    {
        "id": "user2-response",
        "text": "I agree with Alice. I've noticed that I feel more stressed after spending too much time on social media, comparing my life to others.",
        "type": "regular",
        "user": {
            "id": "user2",
            "role": "user",
            "name": "Bob",
            "email": "bob@example.com"
        }
    },
    {
        "id": "user3-response",
        "text": "In my opinion, it's all about balance. Using social media responsibly can help mitigate its negative effects on mental health.",
        "type": "regular",
        "user": {
            "id": "user3",
            "role": "user",
            "name": "Carol",
            "email": "carol@example.com"
        }
    },
    {
        "id": "moderator-followup",
        "text": "How do you think we can promote a healthier relationship with social media?",
        "type": "regular",
        "user": {
            "id": "moderator1",
            "role": "moderator",
            "name": "Kelly",
            "email": "moderator@example.com"
        }
    },
    {
        "id": "user1-suggestion",
        "text": "I think setting time limits for social media use and focusing on real-life interactions can help.",
        "type": "regular",
        "user": {
            "id": "user1",
            "role": "user",
            "name": "Alice",
            "email": "alice@example.com"
        }
    },
    {
        "id": "user2-suggestion",
        "text": "Also, following accounts that promote positivity and mental health awareness can make a big difference.",
        "type": "regular",
        "user": {
            "id": "user2",
            "role": "user",
            "name": "Bob",
            "email": "bob@example.com"
        }
    },
    {
        "id": "user3-suggestion",
        "text": "Educating people, especially the younger generation, about the potential risks of social media is crucial.",
        "type": "regular",
        "user": {
            "id": "user3",
            "role": "user",
            "name": "Carol",
            "email": "carol@example.com"
        }
    },
    // {
    //     "id": "moderator-conclusion",
    //     "text": "Thank you all for sharing your insights! It's clear that awareness and mindful usage are key to a healthier relationship with social media.",
    //     "type": "regular",
    //     "user": {
    //         "id": "moderator1",
    //         "role": "moderator",
    //         "name": "Kelly",
    //         "email": "moderator@example.com"
    //     }
    // }
];
export function returnSampleMessages(userID, role) {
    const messages = sampleMessages;
    if (!userID) return [];
    if (role === "moderator") {
        return messages.map(message => {
            if (message.user.role === "moderator") {
                return {
                    ...message,
                    user: {
                        ...message.user,
                        id: userID
                    }
                };
            }
            return message;
        });
    } else if (role === "user") {
        // Select a random user from the conversation
        const userIDs = messages
            .filter(message => message.user.role === "user")
            .map(message => message.user.id);
        const randomUserID = userIDs[Math.floor(Math.random() * userIDs.length)];

        // Replace the selected user's ID with the provided userID
        return messages.map(message => {
            if (message.user.id === randomUserID) {
                return {
                    ...message,
                    user: {
                        ...message.user,
                        id: userID
                    }
                };
            }
            return message;
        });
    }

    return messages;
}