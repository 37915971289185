// src/services/firebase.js
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyBlyAQTWbcfFiyZrA2uO90sxlT6DKbHPZg",
//   authDomain: "test-4a40f.firebaseapp.com",
//   databaseURL: "https://test-4a40f-default-rtdb.firebaseio.com",
//   projectId: "test-4a40f",
//   storageBucket: "test-4a40f.appspot.com",
//   messagingSenderId: "44348493040",
//   appId: "1:44348493040:web:93fff400afd28bd23c0635",
//   measurementId: "G-XD5H5LMHP0"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAq-gLaeJQvD4A62LFYcZ5suGBKUi3iaNk",
  authDomain: "welisten-401019.firebaseapp.com",
  projectId: "welisten-401019",
  storageBucket: "welisten-401019.appspot.com",
  messagingSenderId: "173830731930",
  appId: "1:173830731930:web:ebd4b50d73f57decde8723",
  measurementId: "G-TECHPY8HXB"
};
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export default firebaseApp;
