import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, getDocs, getDoc, setDoc, doc, runTransaction, arrayUnion, onSnapshot } from 'firebase/firestore';
import { Card, Button, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import useAuth from '../Authentication/useAuth';
import aiavatar1 from "../Assets/images/AI-avatar-1.png";
import aiavatar2 from "../Assets/images/AI-avatar-2.png";
import aiavatar3 from "../Assets/images/AI-avatar-3.png";

import hybridavatar1 from "../Assets/images/hybrid-avatar-1.png";
import hybridavatar2 from "../Assets/images/hybrid-avatar-2.png";
import defaultAvatar from "../Assets/images/default_avatar.png";
import { useChatContext } from 'stream-chat-react';
import styled from 'styled-components';
//import { ErrorPage, LoadingPage } from './ErrorPage';
import { TiDocumentText } from "react-icons/ti";
import { Navigation } from '../App';
const StyledCard = styled(Card)`
box-shadow: 0 1px 2px 2px ${props => props.shadowColor};
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 4px 5px 4px ${props => props.shadowColor}; // Box-shadow for hover state
  }
`;
const selectRandomAvatar = (focusGroup) => {
    if (focusGroup.moderator.toLowerCase() === "ai") {
        // Array of AI avatars
        const aiAvatars = [aiavatar1, aiavatar2, aiavatar3];
        // Randomly select an avatar
        return aiAvatars[Math.floor(Math.random() * aiAvatars.length)];
        // return <></>
    } else if (focusGroup.moderator.toLowerCase() === "hybrid") {
        // Array of hybrid avatars
        const hybridAvatars = [hybridavatar1, hybridavatar2];
        // Randomly select an avatar
        return hybridAvatars[Math.floor(Math.random() * hybridAvatars.length)];
    } else {
        return defaultAvatar
    }
};
const instructions = "Instructions for Participants:\nWe invite you to complete the survey to the best of your ability. Rest assured that your responses are confidential and will only be reviewed by the UMKC research team. The data collected is crucial for reporting on the program's effectiveness and will be used exclusively for funders' reports. We are committed to maintaining the highest level of confidentiality and security with your information.\nPlease click Start Session to start the survey!"

const Survey = () => {
    const { client } = useChatContext();
    const chatClient = client;
    const [focusGroups, setFocusGroups] = useState([]);
    const { user, userData } = useAuth();

    let isModerator = userData && userData.role == 'moderator' ? true : false;

    const [channelsData, setChannelsData] = useState([]);
    const newFocusGroups = [{
        survey_id: 'survey_1',
        name: 'Main Survey',
        selected_topic: "tier-1",
        max_limit: 5,
        start_time: new Date('2024-01-29T00:00:00'),
        end_time: new Date('2024-02-05T00:00:00'),
        moderator: 'AI',
        avatar: selectRandomAvatar({ moderator: 'AI' }),
    },
        // {
        //     survey_id: 'survey_2',
        //     name: 'Secondary Survey',
        //     selected_topic: "tier-5",
        //     max_limit: 5,
        //     start_time: new Date(),
        //     moderator: 'AI',
        //     avatar: selectRandomAvatar({ moderator: 'AI' }),

        // }
    ]

    const navigate = useNavigate();
    useEffect(() => {
        if (userData) {
            const surveyCollectionRef = collection(firestore, 'survey_collection');

            // For each focus group, create a document in firestore survey_collection(Test feature only)
            // newFocusGroups.forEach(async (focusGroup) => {
            //     const focusGroupDocRef = doc(surveyCollectionRef, focusGroup.survey_id);
            //     await setDoc(focusGroupDocRef, focusGroup);
            // });

            //Now, get all focus groups from firestore
            const fetchFocusGroups = async () => {
                const focusGroupsSnapshot = await getDocs(surveyCollectionRef);
                const focusGroupsData = focusGroupsSnapshot.docs.map((doc) => doc.data());
                //for each focus group, change id to id + "-userData.userID"
                focusGroupsData.forEach((focusGroup) => {
                    focusGroup.id = `${focusGroup.survey_id}-${userData.userID}`
                    focusGroup.avatar = selectRandomAvatar({ moderator: 'AI' })
                });
                setFocusGroups(focusGroupsData);
            };
            fetchFocusGroups();

        }
    }, [userData])

    useEffect(() => {
        console.log('focusGroups', focusGroups)

        if (focusGroups.length > 0 && userData && client) {
            focusGroups.forEach((focusGroup) => {

            })

        }
    }, [focusGroups, userData, client])

    useEffect(() => {
        if (client) {
            // client.on('channel.updated', async (event) => {
            //     console.log('channel.updated', event)
            //     getAllChannelData();
            // });
            // client.on('member.added', async (event) => {
            //     console.log('member.added', event)

            //     getAllChannelData();
            // });
            // client.on('member.removed', async (event) => {
            //     console.log('member.removed', event)
            //     getAllChannelData();
            // });
            return () => {
                // client.off('member.added')
                // client.off('member.removed')
                // client.off('channel.updated')
            }
        }
    }, [client])

    const getAllChannelData = async () => {
        const filter = { type: 'survey', members: { $in: ["4iFUA8gxydg3JQdpShqqHVbRkZe2"] } };
        const sort = [{ last_message_at: -1 }];

        try {
            const channels = await chatClient.queryChannels(filter, sort, {
                watch: true,
                state: true,
                presence: true,
            });
            console.log('channels', channels)
            const remoteChannelData = {};
            channels.forEach((channel) => {
                let onlineMembersCount = 0;
                Object.values(channel.state.members).forEach((member) => {
                    if (member.user.online) {
                        onlineMembersCount++;
                    }
                });

                remoteChannelData[channel.data.id] = {
                    channelID: channel.data.id,
                    name: channel.data.name,
                    data: channel.data,
                    onlineMembersCount,
                };
            });
            setChannelsData(remoteChannelData);
            console.log('getAllChannelData', remoteChannelData);

            return remoteChannelData;
        } catch (error) {
            console.error('Error fetching channels:', error);
            return channelsData;
        }
    };



    useEffect(() => { console.log("channelsData", channelsData) }, [channelsData])
    const getStatusAndStyle = (completed) => {
        if (completed) {
            return { text: 'Open', style: { color: 'var(--green)' } };
        }
        return { text: 'Open', style: { color: 'var(--primary)' } };

    };

    const instructions = "Participants are invited to complete the focus group session to the best of their ability. Responses are confidential and reviewed only by the UMKC research team. The collected data is crucial for reporting the program's effectiveness and is used exclusively for funders' reports. The highest level of confidentiality and security is maintained with the information.";
    return (
        // <div>
        <Container className='text-center' style={{ padding: 1, marginTop: 70 }}>
            <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
                <StyledCard shadowColor={getStatusAndStyle(false).style.color}>
                    <Card.Body style={{ marginTop: 0, paddingTop: 0 }}>
                        <Card.Title style={{ color: "var(--primary)", marginTop: 5 }}>
                            <strong>Focus Group Sessions</strong>
                        </Card.Title>

                        <Card.Subtitle>
                            <p style={{ margin: '0' }}>
                                <span style={{ fontWeight: 'bold' }}>Focus Group Overview:</span>
                            </p>
                            <div className='text-start'>
                                These focus group sessions are conducted by the Midwest Center for Nonprofit Leadership at the University of Missouri-Kansas City (UMKC). We are partnering with the United Way of Greater Kansas City to evaluate the outcomes of the initiative.
                            </div>
                            <p style={{ margin: '0' }}>
                                <span style={{ fontWeight: 'bold' }}>Focus Group Instructions:</span>
                            </p>
                            <div className='text-start'>
                                {instructions}
                            </div>
                            <br />
                        </Card.Subtitle>
                    </Card.Body>
                    <Card.Footer>
                        <a href="https://export-sessions-cudbkqbuua-uc.a.run.app" download="sessions.csv">
                            <Button variant='outline-primary'>
                                Download All Session Responses (.ZIP)
                            </Button>
                        </a>
                    </Card.Footer>
                </StyledCard>
            </div>
        </Container>
    );
};

export default Survey;
