import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, getDoc, setDoc, doc, runTransaction, arrayUnion, onSnapshot } from 'firebase/firestore';
import { Card, Button, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import useAuth from '../../Authentication/useAuth';
import aiavatar1 from "../../Assets/images/AI-avatar-1.png";
import aiavatar2 from "../../Assets/images/AI-avatar-2.png";
import hybridavatar1 from "../../Assets/images/hybrid-avatar-1.png";
import hybridavatar2 from "../../Assets/images/hybrid-avatar-2.png";
import defaultAvatar from "../../Assets/images/default_avatar.png";
import { useChatContext } from 'stream-chat-react';
import styled from 'styled-components';
import { ErrorPage, LoadingPage } from './ErrorPage';
import { Navigation } from '../../App';


const StyledCard = styled(Card)`
box-shadow: 0 1px 2px 2px ${props => props.shadowColor};
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 4px 5px 4px ${props => props.shadowColor}; // Box-shadow for hover state
  }
`;
const FocusGroups = () => {
  const { client } = useChatContext();
  const chatClient = client;
  const [focusGroups, setFocusGroups] = useState([]);
  const [selectedFocusGroup, setSelectedFocusGroup] = useState(null);
  const [enrollmentModalShow, setEnrollmentModalShow] = useState(false);
  const [enrollmentSuccessMap, setEnrollmentSuccessMap] = useState({});

  const { user, userData } = useAuth();
  const hasConsent = userData && userData.consent ? userData.consent : false;
  const [userConsent, setUserConsent] = useState(hasConsent);
  const avatarsMap = useRef({});
  let isModerator = userData && userData.role == 'moderator' ? true : false;

  const [channelsData, setChannelsData] = useState([]);
  // Define filter and sort outside so they can be used in both functions
  const filter = { type: 'focus_group' };
  const sort = [{ last_message_at: -1 }];
  const { title } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      if (userData.consent) {
        setUserConsent(userData.consent)
      }
    }
  }, [userData])
  useEffect(() => {
    console.log('userConsent', userConsent)
  })
  useEffect(() => {
    const focusGroupsCollectionRef = collection(firestore, 'focus_groups');
    getAllChannelData();
    const unsubscribe = onSnapshot(focusGroupsCollectionRef, async (snapshot) => {
      // const channelData = await getAllChannelData();

      const focusGroupsData = snapshot.docs.map((doc) => {
        const focusGroup = {
          id: doc.id,
          ...doc.data(),
        };

        // Check if moderator type has changed
        if (!avatarsMap.current[focusGroup.id] || avatarsMap.current[focusGroup.id].moderator !== focusGroup.moderator) {
          avatarsMap.current[focusGroup.id] = {
            avatar: selectRandomAvatar(focusGroup),
            moderator: focusGroup.moderator,
          };
        }

        // Add the avatar from the map to the focus group object
        focusGroup.avatar = avatarsMap.current[focusGroup.id].avatar;


        return focusGroup;
      });

      const filteredFocusGroups = focusGroupsData.filter((group) => {
        return group.name === title;
      });
      
      // Set the filtered focus groups to state
      setFocusGroups(filteredFocusGroups);
      // setFocusGroups(focusGroupsData);

      // Initialize or update enrollmentSuccessMap
      const initialEnrollmentSuccessMap = {};
      focusGroupsData.forEach((group) => {
        initialEnrollmentSuccessMap[group.id] = false;
      });
      setEnrollmentSuccessMap(initialEnrollmentSuccessMap);

    }, (error) => {
      console.error('Error fetching focus groups:', error.message);
    });
    return () => {
      unsubscribe()

    };
  },[title]);
  useEffect(() => {
    if (client) {
      client.on('channel.updated', async (event) => {
        console.log('channel.updated', event)
        getAllChannelData();
      });
      client.on('member.added', async (event) => {
        console.log('member.added', event)

        getAllChannelData();
      });
      client.on('member.removed', async (event) => {
        console.log('member.removed', event)
        getAllChannelData();
      });
      return () => {
        client.off('member.added')
        client.off('member.removed')
        client.off('channel.updated')
      }
    }

  }, [client])

  const enrollUserInFocusGroup = async () => {
    try {
      // Get the current user's ID from the authentication details
      const userId = auth.currentUser?.uid;

      if (!userId || !selectedFocusGroup) {
        console.error('User ID or selected focus group not available.');
        return;
      }

      const focusGroupDocRef = doc(firestore, 'focus_groups', selectedFocusGroup.id);

      // Update the focused group with the enrolled participant (using the user's ID)
      await runTransaction(firestore, async (transaction) => {
        const focusGroupSnapshot = await transaction.get(focusGroupDocRef);

        if (focusGroupSnapshot.exists()) {
          const focusGroupId = selectedFocusGroup.id;

          // Check if the user is already enrolled to avoid unnecessary updates
          if (!focusGroupSnapshot.data().scheduled_participants.includes(userId)) {
            transaction.update(focusGroupDocRef, {
              scheduled_participants: arrayUnion(userId),
            });

            // Set enrollment success flag for the specific focus group
            setEnrollmentSuccessMap((prevMap) => ({
              ...prevMap,
              [focusGroupId]: true,
            }));
          }
        }
      });

      // Close the enrollment modal
      setEnrollmentModalShow(false);
    } catch (error) {
      console.error('Error enrolling participant:', error.message);
    }
  };
  const getAllChannelData = async () => {
    console.log('getAllChannelData');
    const filter = { type: 'focus_group' };
    const sort = [{ last_message_at: -1 }];

    try {
      const channels = await chatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
        presence: true,
      });

      const remoteChannelData = {};
      channels.forEach((channel) => {
        let onlineMembersCount = 0;
        Object.values(channel.state.members).forEach((member) => {
          if (member.user.online) {
            onlineMembersCount++;
          }
        });

        remoteChannelData[channel.data.id] = {
          channelID: channel.data.id,
          name: channel.data.name,
          data: channel.data,
          onlineMembersCount,
          memberIDs: Object.keys(channel.state.members),
        };
      });
      setChannelsData(remoteChannelData);
      return remoteChannelData;
    } catch (error) {
      console.error('Error fetching channels:', error);
      return channelsData;
    }
  };



  useEffect(() => { console.log("channelsData", channelsData) }, [channelsData])
  const navigateToFocusGroup = (focusGroup) => {
    setSelectedFocusGroup(focusGroup);
    navigate(`/focus-groups/${focusGroup.id}`);
  };

  const openEnrollmentModal = (focusGroup) => {
    setSelectedFocusGroup(focusGroup);
    setEnrollmentModalShow(true);
  };

  const closeEnrollmentModal = () => {
    setEnrollmentModalShow(false);
  };

  const isSessionOpen = (focusGroup) => {
    if (focusGroup) {
      const currentTime = new Date();
      return currentTime >= focusGroup.start_time.toDate();
    }
    return false;
  };
  const handleJoin = async (focusGroupInfo) => {
    try {
      // If the user has not given consent, ask for consent
      if (!userConsent) {
        const consentAgreed = window.confirm('By joining, you agree to our terms and conditions. Do you agree?');
        if (consentAgreed) {
          // Set user consent in Firestore users collection
          if (userData && userData.userID) {
            const userDocRef = doc(firestore, 'users', userData.userID);
            await setDoc(userDocRef, { consent: true }, { merge: true });
          }
          setUserConsent(true);
        } else {
          return;
        }
      }

      if (focusGroupInfo.started) {
        alert('The session has started. Cannot join.');
      } else {
        navigate(`/focus-groups/${focusGroupInfo.group_id}/chat`);
      }
    } catch (error) {
      console.error('Error during joining:', error.message);
    }
  };

  const handleObserve = (focusGroupInfo) => {
    console.log('Observe button clicked');
    navigate(`/focus-groups/${focusGroupInfo.group_id}/observe`);
  };

  // const handleGoBack = () => {
  //   navigate.goBack();
  // };
  const getStatusAndStyle = (focusGroup, channelData) => {
    if (channelData) {
      console.log('channelData', channelData)
      if (channelData.data.open == false) {
        return { text: 'Closed', style: { color: 'var(--primaryred)' } };
      }
      if (isSessionOpen(focusGroup)) {
        if (channelData.data.started) {
          return { text: 'Started', style: { color: 'var(--primary)' } };
        } else {
          return { text: 'Open', style: { color: 'var(--green)' } };
        }
      } else if (channelData.onlineMembersCount >= focusGroup.max_limit) {
        return { text: 'Full', style: { color: 'var(--yellow)' } };
      } else if (!isSessionOpen(focusGroup)) {
        return { text: 'Not Yet Open', style: { color: 'var(--gray)' } };
      } else {
        return { text: 'Closed', style: { color: 'var(--primaryred)' } };
      }
    }
    return { text: 'N/A', style: { color: 'var(--primaryred)' } };

  };

  const selectRandomAvatar = (focusGroup) => {
    if (focusGroup.moderator.toLowerCase() === "ai") {
      // Array of AI avatars
      const aiAvatars = [aiavatar1, aiavatar2];
      // Randomly select an avatar
      return aiAvatars[Math.floor(Math.random() * aiAvatars.length)];
    } else if (focusGroup.moderator.toLowerCase() === "hybrid") {
      // Array of hybrid avatars
      const hybridAvatars = [hybridavatar1, hybridavatar2];
      // Randomly select an avatar
      return hybridAvatars[Math.floor(Math.random() * hybridAvatars.length)];
    } else {
      return defaultAvatar
    }
  };

  if (chatClient === null) return <LoadingPage />;
  if (chatClient === undefined) return <ErrorPage />;
  return (
    // <div>
    <div className='text-center' style={{ padding: 1, marginTop: 70 }} >
      <Navigation user={user} userData={userData} />

      <div>
        <Row style={{ marginTop: 20 }}>
          <Col xs={12} md={4} className="d-flex align-items-center justify-content-start">
            <Button style={{ marginLeft: 20 }}
              onClick={() => navigate(`/`)}
              variant='secondary'
            >
              Back to Main Screen
            </Button>
          </Col>
          <Col xs={12} md={4} className="d-flex align-items-center justify-content-center">
            <h2 style={{ color: "var(--primary)" }}>
              <strong>Focus Groups Details</strong>
            </h2>
          </Col>
          <Col md={4} className="d-none d-md-flex"> {/* Hidden on xs to md screens */}
            {/* This column is empty, used to balance the layout */}
          </Col>
        </Row>



        <Row style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
          {channelsData && Object.keys(channelsData).length > 0 && userData && focusGroups.map((focusGroup) => {
            const currentChannelData = channelsData[focusGroup.group_id]
            let sessionStarted = false;
            let moderator_id = null;
            let moderator_info = null;
            let memberIDs = [];
            if (currentChannelData && currentChannelData.data) {
              sessionStarted = currentChannelData.data.started ? currentChannelData.data.started : false;
              moderator_id = currentChannelData.data.moderator_id ? currentChannelData.data.moderator_id : null;
              moderator_info = currentChannelData.data.moderator_info ? currentChannelData.data.moderator_info : null;
              memberIDs = currentChannelData ? currentChannelData.memberIDs : [];
            }
            console.log('sessionStarted', currentChannelData)
            return <Col md={3} key={focusGroup.id} className="mb-3">
              <StyledCard shadowColor={getStatusAndStyle(focusGroup, currentChannelData).style.color} key={focusGroup.id}>
                <Card.Body>
                  <Card.Img variant="top" src={focusGroup.avatar} />
                  <Card.Title style={{ color: "var(--primary)", marginTop: 5 }}><strong>{focusGroup.group_id || `Focus Group ${focusGroup.id}`}</strong></Card.Title>

                  <Card.Subtitle >
                    Session Status: <strong style={getStatusAndStyle(focusGroup, currentChannelData).style}>
                      {getStatusAndStyle(focusGroup, currentChannelData).text}
                    </strong>
                    {moderator_info && <div>
                      Moderated By: <strong style={getStatusAndStyle(focusGroup, currentChannelData).style}>{moderator_info.nickname}</strong></div>}
                  </Card.Subtitle>
                  <Card.Text as="div">
                    <Row>
                      <Col xs={6} className="text-start">Online Participants:</Col>
                      <Col xs={6} className="text-end"><strong>{currentChannelData ? currentChannelData.onlineMembersCount : 0}/{focusGroup.max_limit}</strong></Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="text-start">Session Start Time:</Col>
                      <Col xs={6} className="text-end"><strong>{focusGroup.start_time.toDate().toLocaleString()}</strong></Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="text-start">Session Mode:</Col>
                      <Col xs={6} className="text-end"><strong>{focusGroup.moderator}</strong></Col>
                    </Row>
                    {!sessionStarted && <Row>
                      <Col xs={6} className="text-start">Scheduled Participants:</Col>
                      <Col xs={6} className="text-end"><strong>{focusGroup.scheduled_participants?.length || 0}</strong></Col>
                    </Row>}

                  </Card.Text>
                </Card.Body>
                <Card.Footer>

                  <Button
                    disabled={sessionStarted && (moderator_id !== userData.userID) && !memberIDs.includes(userData.userID)}
                    onClick={() => {
                      if (!sessionStarted || (moderator_id === userData.userID) || memberIDs.includes(userData.userID)) {
                        handleJoin(focusGroup);
                      }
                    }}
                  >
                    {moderator_id === userData.userID || memberIDs.includes(userData.userID) ? "Continue" : (isModerator ? "Moderate" : "Join")}
                  </Button>

                  <Button variant='info' onClick={() => handleObserve(focusGroup)}>Observe</Button>
                  {!isSessionOpen(focusGroup) && <Button
                    onClick={() => openEnrollmentModal(focusGroup)}
                    disabled={enrollmentSuccessMap[focusGroup.id]}
                  >
                    {enrollmentSuccessMap[focusGroup.id] ? 'Scheduled' : 'Schedule'}
                  </Button>}
                </Card.Footer>
              </StyledCard>
            </Col>
          }


          )}
        </Row>
        
        <Modal show={enrollmentModalShow} onHide={closeEnrollmentModal}>
          <Modal.Header closeButton>
            <Modal.Title>Schedule for {selectedFocusGroup?.name || 'Focus Group'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Content as needed */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEnrollmentModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={enrollUserInFocusGroup}
              disabled={enrollmentSuccessMap[selectedFocusGroup?.id]}
            >
              Schedule
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    </div>
  );
};

export default FocusGroups;