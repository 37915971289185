// SignIn.js
import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Form, Button, Container, Alert, Row, Col, Card, CardBody, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";
import logo from '../Assets/images/welisten_logo_new_transparent.png'

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const moderators = [
        { username: 'moderator1@gmail.com', password: 'moderator1' },
        { username: 'moderator2@gmail.com', password: 'moderator2' },
        { username: 'moderator3@gmail.com', password: 'moderator3' },
        { username: 'moderator4@gmail.com', password: 'moderator4' },
    ];

    const users = [
        { username: 'welisten1@gmail.com', password: 'happylisten1' },
        { username: 'welisten2@gmail.com', password: 'happylisten2' },
        { username: 'welisten3@gmail.com', password: 'happylisten3' },
        { username: 'welisten4@gmail.com', password: 'happylisten4' },
        { username: 'welisten5@gmail.com', password: 'happylisten5' },
        { username: 'welisten6@gmail.com', password: 'happylisten6' },
        { username: 'welisten7@gmail.com', password: 'happylisten7' },
        { username: 'welisten8@gmail.com', password: 'happylisten8' },
        { username: 'welisten9@gmail.com', password: 'happylisten9' },
        { username: 'welisten10@gmail.com', password: 'happylisten10' },
        { username: 'welisten11@gmail.com', password: 'happylisten11' },
        { username: 'welisten12@gmail.com', password: 'happylisten12' },
        { username: 'welisten13@gmail.com', password: 'happylisten13' },
        { username: 'welisten14@gmail.com', password: 'happylisten14' },
        { username: 'welisten15@gmail.com', password: 'happylisten15' },
        { username: 'welisten16@gmail.com', password: 'happylisten16' },
        { username: 'welisten17@gmail.com', password: 'happylisten17' },
        { username: 'welisten18@gmail.com', password: 'happylisten18' },
        { username: 'welisten19@gmail.com', password: 'happylisten19' },
        { username: 'welisten20@gmail.com', password: 'happylisten20' },
        { username: 'welisten21@gmail.com', password: 'happylisten21' },
        { username: 'welisten22@gmail.com', password: 'happylisten22' },
        { username: 'welisten23@gmail.com', password: 'happylisten23' },
        { username: 'welisten24@gmail.com', password: 'happylisten24' },
        { username: 'welisten25@gmail.com', password: 'happylisten25' }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                // Sign-in successful, you can redirect or perform other actions here
            })
            .catch((err) => {
                // Handle errors with user-friendly messages
                let errorMessage;
                switch (err.code) {
                    case 'auth/invalid-email':
                        errorMessage = 'The email address is not valid.';
                        break;
                    case 'auth/user-disabled':
                        errorMessage = 'This user has been disabled.';
                        break;
                    case 'auth/user-not-found':
                        errorMessage = 'There is no user corresponding to this email.';
                        break;
                    case 'auth/wrong-password':
                        errorMessage = 'The password is incorrect.';
                        break;
                    case 'auth/invalid-credential':
                        errorMessage = 'The email or password is incorrect. Please try again.';
                        break;
                    default:
                        errorMessage = 'An error occurred during sign-in. Please try again.';
                }
                setError(errorMessage);
            });
    };


    const handleSetCredentials = (credentials) => {
        setEmail(credentials.username);
        setPassword(credentials.password);
    };

    const handleRandomSelect = (array) => {
        const randomIndex = Math.floor(Math.random() * array.length);
        handleSetCredentials(array[randomIndex]);
    };

    useEffect(() => {
        // Check if browser is Firefox
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
            // Display a popup window suggesting to use Chrome, Safari, or Edge
            alert('For optimal performance, we recommend using Chrome, Safari, or Edge instead of Firefox.');
        }
    }, []);

    return (
        <Container className="pt-lg-7">
            {error && <Alert variant="danger">{error}</Alert>}
            <Row className="justify-content-center">
                <Col lg="5">
                    <Card className="bg-secondary shadow border-0">
                        <div className='px-5 py-2'>
                            <Card.Img src={logo} height={100} width={40}></Card.Img>
                        </div>
                        <CardBody className="px-lg-5 pb-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h5 className='text-muted'>Sign in with your credentials</h5>
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                                <div className='mt-2 text-center'><Button variant="primary" type="submit" style={{ marginRight: 3 }}>
                                    Sign In
                                </Button>
                                    <Link to="/signup" className="btn btn-secondary">
                                        Sign Up
                                    </Link></div>

                            </Form>
                            <Row className='mt-2'>
                                <Col className="text-right" xs="6">

                                </Col>
                                <Col xs="6" className='text-right'>
                                    <a
                                        className=""
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <small>Forgot password?</small>
                                    </a>
                                </Col>

                            </Row>
                            {/* <div className='mt-2 p-3 text-center border border-warning rounded' >

                                <Row>
                                    <Col>
                                        <h5>Quick Access</h5>
                                        <p className='text-warning text-sm' style={{ fontSize: 15 }}>Note: This is a development feature only. An official WeListen account is required to access the portal.</p>
                                        <div>
                                            <h6>Moderators</h6>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary">
                                                    Select A Moderator
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {moderators.map((moderator, index) => (
                                                        <Dropdown.Item key={index} onClick={() => handleSetCredentials(moderator)}>
                                                            {moderator.username}
                                                        </Dropdown.Item>
                                                    ))}                                            
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <br></br>
                                        <div>
                                            <h6>Participants</h6>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary">
                                                    Select A Participant
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {users.map((user, index) => (
                                                        <Dropdown.Item key={index} onClick={() => handleSetCredentials(user)}>
                                                            {user.username}
                                                        </Dropdown.Item>
                                                    ))}                                                    
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>

                            </div> */}

                        </CardBody>

                    </Card>

                </Col>
            </Row>


        </Container>
    );
}

export default SignIn;
