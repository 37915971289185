import { BiUserCircle } from "react-icons/bi";
import { GrPersonalComputer, GrUserAdmin } from "react-icons/gr";
import { RiComputerFill } from "react-icons/ri";
import LineTo from 'react-lineto';
import React, { useState, useEffect } from "react";
// import Select from "react-select";


export default function Network() {



	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? '#000' : 'black', // Adjust colors as needed
			color: 'white',
			cursor: 'pointer',
			zIndex: 1000,
			position: 'relative',

		}),
	};



	const convs = {
		len: 4, rdata: [
			{ name: "welcome", label: "Welcome" },
			{ name: "rock", label: "Q1" },
			{ name: "jazz", label: "Q2" },
			{ name: "orchestra", label: "Q3" },
		]
	};


	const [doptions, setDoptions] = useState();
	const [rdata, setrdata] = useState([]);
	const [slicedata, setslicedata] = useState([]);
	const [netdata, setnetdata] = useState([]);
	const [tabdata, settabdata] = useState([]);
	const [selected, setSelected] = useState(null);

	const handleChange = (selectedOption) => {
		setSelected(selectedOption);
		console.log(`Option selected:`, selectedOption);
		var result = rdata.filter(obj => obj['Segment'] === selectedOption.value);
		console.log("netresult is", result.length);
		setnetdata(result);
		settabdata(result);
		setslicedata(result);
	};



	const fetchUserData = () => {

		fetch('http://127.0.0.1:5000/getquestiondata')
			.then((res) => res.json())
			.then((json) => {

				setDoptions(json);

			})
			.catch(error => {
				console.error("Error fetching user data:", error);
			});

		fetch('http://127.0.0.1:5000/getdata')
			.then((res) => res.json())
			.then((json) => {

				setrdata(json);
				settabdata(json.slice(0, 8));
			})
			.catch(error => {
				console.error("Error fetching user data:", error);
			});

	};

	useEffect(() => {
		fetchUserData()

	}, [])


	function handleChange1(event) {
		let key = event.target.id;
		console.log(event.target.value);
		console.log(event.target.id);
		var result = slicedata.filter(obj => obj[key].includes(event.target.value));
		console.log("result is", result);
		settabdata(result);
	}

	return (
		<div class="row">
			<div className="col-md-6">
				<div class="container m-2">
					{/* <Select class="w-60" options={doptions} onChange={handleChange} defaultValue={{ label: "Welcome", value: "Welcome" }} styles={customStyles} autoFocus={true} /> */}
				</div>
				<div class="row">
					<span style={{ fontSize: 78, zIndex: 100, position: 'relative' }} class="offset-md-4 z"><GrUserAdmin /></span>
					<span style={{ fontSize: 78, zIndex: 100, position: 'relative' }} class="offset-md-1 a"><span style={{ fontSize: 20 }}>Clint</span><BiUserCircle /> </span>
				</div>
				{netdata.length >= 2 && <div class="row">
					<span style={{ fontSize: 78, zIndex: 100, position: 'relative' }} class="offset-md-1 b"><span style={{ fontSize: 20 }}>Clint</span><BiUserCircle /> </span>
					<span style={{ fontSize: 78, zIndex: 100, position: 'relative' }} class="offset-md-7 c"> <BiUserCircle /><span style={{ fontSize: 20, color: 'black' }}>Clint</span></span>
				</div>
				}

				<div class="row">
					<span style={{ fontSize: 78, color: 'blue' }} class="offset-md-1 d"> <span style={{ fontSize: 20, color: 'black' }}>Clint</span><BiUserCircle /> </span>
					<span style={{ fontSize: 78, color: 'red' }} class="offset-md-7 e"> <BiUserCircle /><span style={{ fontSize: 20, color: 'black' }}>Clint</span></span>
				</div>

				<div class="row">
					<span style={{ fontSize: 78, color: 'blue' }} class="offset-md-1 g"> <span style={{ fontSize: 20, color: 'black' }}>Clint</span><BiUserCircle /> </span>
					<span style={{ fontSize: 108, zIndex: 100, position: 'relative', color: 'black' }} class="offset-md-3 f"> <RiComputerFill /></span>
					<span style={{ fontSize: 78, color: 'red' }} class="offset-md-3 h"> <BiUserCircle /><span style={{ fontSize: 20, color: 'black' }}>Clint</span></span>
				</div>
				<div class="row">
					<span style={{ fontSize: 78, color: 'blue' }} class="offset-md-1 i"> <span style={{ fontSize: 20, color: 'black' }}>Clint</span><BiUserCircle /> </span>
					<span style={{ fontSize: 78, color: 'red' }} class="offset-md-7 j"> <BiUserCircle /><span style={{ fontSize: 20, color: 'black' }}>Clint</span></span>
				</div>
				<div class="row">
					<span style={{ fontSize: 78, color: 'blue' }} class="offset-md-1 k"> <span style={{ fontSize: 20, color: 'black' }}>Clint</span><BiUserCircle /> </span>
					<span style={{ fontSize: 78, color: 'red' }} class="offset-md-7 l"> <BiUserCircle /><span style={{ fontSize: 20, color: 'black' }}>Clint</span></span>
				</div>
				<div class="row">
					<span style={{ fontSize: 78, color: 'green' }} class="offset-md-3 m"> <span style={{ fontSize: 20, color: 'black' }}>Clint</span><BiUserCircle /></span>
					<span style={{ fontSize: 78, color: 'green' }} class="offset-md-2 n"> <span style={{ fontSize: 20, color: 'black' }}>Clint</span><BiUserCircle /></span>

				</div>
				<LineTo from="offset-md-1 a" to="offset-md-3 f" delay="0" borderWidth={5} zIndex={100} borderColor="black" fromAnchor="bottom" toAnchor="center" />
				<LineTo from="offset-md-1 b" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="purple" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-7 c" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="brown" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-1 d" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-7 e" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-1 g" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-3 h" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-1 i" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-7 j" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-1 k" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-7 l" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-3 m" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-2 n" to="offset-md-3 f" delay="0" borderWidth={5} zIndex="1" borderColor="black" fromAnchor="center" toAnchor="center" />
				<LineTo from="offset-md-4 z" to="offset-md-3 f" delay="0" borderWidth={5} zIndex={100} borderColor="black" fromAnchor="bottom" toAnchor="center" />
			</div>
			<div className="col-md-6">
				<table class="table m-1">
					<thead class="thead-dark">
						<tr>
							<th>Segment</th>
							<th>Username</th>
							<th>Dialogue</th>
							<th>Topic</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<input type="text" class="form-control" id="Segment" onChange={handleChange1} /></td>
							<td>
								<input type="text" class="form-control" id="username" onChange={handleChange1} /></td>
							<td>
								<input type="text" class="form-control" id="text" onChange={handleChange1} /></td>
							<td>
								<input type="text" class="form-control" id="Dominant_Topic" onChange={handleChange1} /></td>
						</tr>
						{tabdata.map(conv => (
							<tr>
								<td>
									{conv.Segment}</td>
								<td>
									{conv.username}</td>
								<td>
									{conv.text}</td>
								<td>
									{conv.Dominant_Topic}</td>
							</tr>
						))}

					</tbody>
				</table>
			</div>


		</div>
	);
}