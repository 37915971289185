import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { doc, setDoc, collection, getDocs } from 'firebase/firestore';
import { parseISO } from 'date-fns';
import './pages.css';
import classnames from 'classnames';
import {
  FormGroup,
  Container,
  Form,
  Input,
  Select,
  Option,
  InputGroupText,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  Row,
  Col,
} from 'reactstrap';

function Page1() {
  const [groupID, setGroupID] = useState('');
  const [maxLimit, setMaxLimit] = useState('');
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [moderator, setModerator] = useState('');
  const [passcode, setPasscode] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(''); // Add state for the selected topic
  const [topics, setTopics] = useState([]); // Add state to store available topics

  useEffect(() => {
    // Fetch the topics from the 'survey_questions' collection
    const fetchTopics = async () => {
      try {
        const surveyQuestionsCollection = collection(firestore, 'survey_questions');
        const querySnapshot = await getDocs(surveyQuestionsCollection);
        const topicsList = querySnapshot.docs.map((doc) => doc.id);
        setTopics(topicsList);
        // console.log(topicsList)
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    fetchTopics();
  }, []);

  const handleScheduleMeeting = async () => {
    try {
      // Parse the formatted date-time strings into JavaScript Date objects
      const parsedStartTime = parseISO(startTime);

      // Create a reference to the document
      const groupDocRef = doc(firestore, 'focus_groups', `${groupID}`);

      // Define an object with the fields you want to update, including the passcode and selectedTopic
      const updateData = {
        group_id: {groupID},
        max_limit: parseInt(maxLimit),
        name: name,
        start_time: parsedStartTime,
        moderator: moderator,
        passcode: passcode,
        selected_topic: selectedTopic, // Add the selectedTopic field
        current_participants: 0,
        scheduled_participants: [],
        started: false,
        ready: false,
        current_progress: 0,
        moderator_online: false,
        moderator_name: null,
      };

      // Update the document with the specified fields, without affecting other fields
      await setDoc(groupDocRef, updateData, { merge: true });

      // Call the API to create a channel
      const response = await fetch(`http://localhost:3000/api/createchannel?channelID=focus_group_${groupID}&userID=admin`);
      const data = await response.json();

      // Clear input fields after scheduling
      setGroupID('');
      setMaxLimit('');
      setName('');
      setStartTime('');
      setModerator('');
      setPasscode('');
      setSelectedTopic('');

      alert('Meeting scheduled successfully.'); // You can add a success message
    } catch (error) {
      console.error('Error scheduling meeting: ', error);
      alert('Error scheduling meeting. Please try again.'); // Handle the error gracefully
    }
  };


  return (
    <>
      <Container className="mb-5">
        {/* Inputs */}
        <h3 className="h4 text-primary font-weight-bold mb-4 text-center">Schedule Focus Group Meeting</h3>
        <Row className='justify-content-center'>
          <Col lg="6" sm="10">
            <FormGroup>
              <Input placeholder="Group ID" type="text" value={groupID} onChange={(e) => setGroupID(e.target.value)} />
            </FormGroup>
            <FormGroup>
              {/* <label>Maximum Limit:</label> */}
              <Input placeholder="Maximum Limit" type="number" value={maxLimit} onChange={(e) => setMaxLimit(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Input placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Input placeholder="Start Date & Time" type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Input placeholder="Moderator" type="text" value={moderator} onChange={(e) => setModerator(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Input placeholder="Passcode (4 digits)" type="text" value={passcode} onChange={(e) => setPasscode(e.target.value)} />
            </FormGroup>
            <div className="d-flex w-100">
              <FormGroup className="w-100">
                <UncontrolledDropdown className='dropdown-menu-lg-end w-100'>

                  <DropdownToggle caret color="secondary" className='w-100'>
                    {selectedTopic ? selectedTopic : 'Selected Topic'}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <DropdownItem onClick={() => setSelectedTopic("")}>Select a topic</DropdownItem>
                    {topics.map((topic, index) => (
                      <DropdownItem key={index} onClick={() => setSelectedTopic(topic)}>
                        {topic}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

              </FormGroup>
            </div>
          </Col>
        </Row>
        <FormGroup className="text-center">
          <Button color='primary' onClick={handleScheduleMeeting}>Schedule Meeting</Button>
        </FormGroup>

      </Container>
    </>
    // <div>
    //   <h2>Schedule Focus Group Meeting</h2>
    //   <div>
    //     <label>Group ID:</label>
    //     <input type="text" value={groupID} onChange={(e) => setGroupID(e.target.value)} />
    //   </div>
    //   <div>
    //     <label>Maximum Limit:</label>
    //     <input type="number" value={maxLimit} onChange={(e) => setMaxLimit(e.target.value)} />
    //   </div>
    //   <div>
    //     <label>Name:</label>
    //     <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
    //   </div>
    //   <div>
    //     <label>Start Date & Time:</label>
    //     <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
    //   </div>
    //   <div>
    //     <label>Moderator:</label>
    //     <input type="text" value={moderator} onChange={(e) => setModerator(e.target.value)} />
    //   </div>
    //   <div>
    //     <label>Passcode (4 digits):</label>
    //     <input type="text" value={passcode} onChange={(e) => setPasscode(e.target.value)} />
    //   </div>
    //   <div>
    //     <label>Topic:</label>
    //     <select value={selectedTopic} onChange={(e) => setSelectedTopic(e.target.value)}>
    //       <option value="">Select a topic</option>
    //       {topics.map((topic) => (
    //         <option key={topic} value={topic}>
    //           {topic}
    //         </option>
    //       ))}
    //     </select>
    //   </div>
    //   <button onClick={handleScheduleMeeting}>Schedule Meeting</button>
    // </div>
  );
}


export default Page1;
