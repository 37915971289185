import { Button } from 'react-bootstrap'
import unavailableImg from '../../Assets/images/unavailable.png'
import { useNavigate } from 'react-router-dom';
import { LoadingIndicator } from 'stream-chat-react';
import { Grid } from 'react-loading-icons';

export const ErrorPage = () => {
    const navigate = useNavigate();

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '85vh',
        }}
    >
        <img src={unavailableImg} height={150} alt="Unavailable" />
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
            <h4 style={{ marginBottom: 0 }}><b>Something is wrong.</b></h4>
            <h5>Please try refreshing the page.</h5>
            <p className='text-small'>Please check the following:</p>
            <ul style={{ marginLeft: 30, listStyleType: 'disc', textAlign: 'left' }}>
                <li>Ensure you have a stable internet connection.</li>
                <li>Try accessing the site in a different browser. We recommend Chrome or Firefox for the best experience.</li>
                <li>Clear your browser cache and cookies, then try reloading the page.</li>
                <li>Check if your browser is up to date.</li>
            </ul>
            <Button className="mb-3" variant='secondary' onClick={() => { navigate('/') }}>Refresh</Button>



            <p className='text-small'>If the issue persists, please contact our administration at support@welisten-ai.org</p>
        </div>
    </div>

}

export const LoadingPage = () => {
    return <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
        }}
    >
        <LoadingIndicator size={100} />
    </div>
}
export const LoadingPageGrid = () => {
    return <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '90vh',
        }}
    >
        <Grid height="130" width="130" stroke="black" speed={.75} fill='var(--primary)' />

    </div>
}
export const QRErrorPage = () => {
    const navigate = useNavigate();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '85vh',
            }}
        >
            <img src={unavailableImg} height={150} alt="Unavailable" />
            <div style={{ marginTop: '30px', textAlign: 'center' }}>
                <h4 style={{ marginBottom: 0 }}><b>QR Code Confirmation Not Found</b></h4>
                <h5>The confirmation number from the QR code does not match our records.</h5>
                <p className='text-small'>Please try the following:</p>
                <ul style={{ marginLeft: 30, listStyleType: 'disc', textAlign: 'left' }}>
                    <li>Double-check the QR code to ensure it was scanned correctly.</li>
                    <li>If you manually entered a confirmation number, please verify that it was entered correctly.</li>
                    <li>Ensure that the QR code is for the correct event or purpose.</li>
                    <li>If the QR code is a printout, check that it hasn't been altered or damaged.</li>
                    <li>Double-check your internet connection.</li>
                </ul>
                <Button className="mb-3" variant='secondary' onClick={() => navigate('/')}>Back to Home</Button>

                <p className='text-small'>If you're still experiencing issues, please bring the QR code to an event organizer or a person in authority for assistance.</p>
                <p>For further support, contact us at support@welisten-ai.org</p>
            </div>
        </div>
    );
};

