// ChatPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Chat,
  Channel,
  Window,
  MessageList,
  MessageInput,
  LoadingIndicator,
  useChatContext,
  useChannelActionContext,
  SendButton,
  ChatAutoComplete,
  useChannelStateContext,
  useMessageInputContext,
  useTranslationContext,
  MessageSimple,
  useMessageContext,
} from 'stream-chat-react';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { Button, ButtonGroup, Card, Col, Collapse, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useAuth from '../../Authentication/useAuth';
import 'stream-chat-react/dist/css/v2/index.css';
import Tour from 'reactour';
import { FaMicrophone, FaVolumeUp } from 'react-icons/fa';
import { IoMdSend } from "react-icons/io";
import ProgressBar from 'react-bootstrap/ProgressBar';
import unavailableImg from '../../Assets/images/unavailable.png'
import { FaGlasses } from "react-icons/fa6";
import { BsFileBarGraph } from "react-icons/bs";
import QRCodeGenerator, { generateUniqueId } from './QRCodeGenerator';
import JoinSurveyFeedback from './JoinSurveyFeedback';
import { HiOutlineSpeakerWave, HiOutlineSpeakerXMark } from "react-icons/hi2";
import { FaArrowRightLong } from "react-icons/fa6";
import "./streamchat_override.css"
import { returnSampleMessages } from './Resources/sampleMessages';
import { MD5 } from 'crypto-js';
import { storage } from '../../firebase';
import { getDownloadURL, ref } from '@firebase/storage';
const firestore = getFirestore();

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return height;
}

const CustomChannelHeader = (props) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const navigate = useNavigate();
  const { userData } = useAuth();
  const isModerator = userData && userData.role === 'moderator';
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('');

  const [userWordCount, setUserWordCount] = useState(0);
  const { channel } = useChannelStateContext();
  const [sessionStarted, setSessionStarted] = useState(false);

  const [allUserStats, setAllUserStats] = useState({});
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [focusGroupData, setFocusGroupData] = useState(null);
  const [onlineMembers, setOnlineMembers] = useState([]);
  useEffect(() => {
    if (props.focusGroupId) {
      const focusGroupDocRef = doc(firestore, 'focus_groups', props.focusGroupId);
      const focusGroupDocSnapshot = getDoc(focusGroupDocRef).then((doc) => {
        if (doc.exists()) {
          const focusGroupData = doc.data();
          setFocusGroupData(focusGroupData);
        }
      });
    }
  }, [props.focusGroupId])
  // console.log('props.isTTSon', props.isTTSon);
  let synthesizeServer = process.env.REACT_APP_SYNTHESIZE
  if (!synthesizeServer)
    synthesizeServer = "https://synthesize-cudbkqbuua-uc.a.run.app"
  // console.log('synthesizeServer', synthesizeServer)
  const playSynthesizedAudio = async (text, langCode = 'en-US', retryCount = 0) => {
    const modelNameEN = 'en-US-Wavenet-F';
    const modelNameES = 'es-US-Neural2-A';
    const hash = MD5(text).toString();
    const languageCode = langCode.startsWith('es') ? "es-US" : "en-US";
    const modelName = languageCode.startsWith('es') ? modelNameES : modelNameEN;
    const fileName = `audio/${modelName}/${hash}.mp3`;
    const synthesizeUrl = `${synthesizeServer}?text=${encodeURIComponent(text)}&langCode=${encodeURIComponent(langCode)}`;
    const fileRef = ref(storage, fileName);
    console.log('synthesizeUrl', synthesizeUrl);

    try {
      const audioUrl = await getDownloadURL(fileRef);
      console.log('Audio URL:', audioUrl);
      const audio = new Audio(audioUrl);
      props.setIsPlaying(true);
      await audio.play();
      audio.onended = () => {
        props.setIsPlaying(false);
      };
    } catch (error) {
      console.error('Error fetching audio file:', error);
      if (retryCount < 5) { // Set a maximum number of retries to avoid infinite loop
        console.log('Retrying...');
        // Trigger the synthesis if it's the first retry
        if (retryCount === 0) {
          await fetch(synthesizeUrl);
        }
        // Wait for a few seconds before retrying
        await new Promise(resolve => setTimeout(resolve, 2000));
        // Retry with an increased retry count
        await playSynthesizedAudio(text, langCode, retryCount + 1);
      } else {
        console.error('Max retries reached. Unable to play audio.');
        // Handle the case where the audio file could not be fetched after retries
      }
    }
  };

  const handleUserMessage = async (event) => {
    const { user, message } = event;
    // console.log('event', event)
    if (user && message && message.text && message.type != 'system') {
      const words = message.text.split(/\s+/).filter((word) => word !== '');
      const userID = user.id;
      console.log(userID, 'words', words)
      console.log('handleUserMessage user', user)
      const simplifiedUser = {
        // userID: user.userID,
        nickname: user.nickname,
        // role: user.role 
      };
      const remoteUserStats = channel.data.userStats ? channel.data.userStats : {};
      let sentUserStats = remoteUserStats[userID] ? remoteUserStats[userID] : { wordCount: 0, user: simplifiedUser };
      sentUserStats = { user: simplifiedUser, wordCount: sentUserStats.wordCount + words.length };
      console.log('userStats (new)', simplifiedUser['nickname'], sentUserStats, channel.data.userStats);

      const newAllUserStats = { ...remoteUserStats, [userID]: sentUserStats };
      await channel.updatePartial({ set: { userStats: newAllUserStats } });
      // console.log('userID', userID, userData.userID, props.isTTSon)
      if (userData && userID != userData.userID) {
        if (props.isTTSon) {
          await playSynthesizedAudio(message.text);
        }
      }
    }
  };

  const handleChannelUpdate = async (event) => {
    const { channel } = event;
    console.log('updated channel', channel);

    const members = channel.members;
    const remoteSessionStarted = channel.started;
    const remoteProgress = channel.currentProgress ? channel.currentProgress : 0;
    const remoteCurrentQuestion = channel.currentQuestion ? channel.currentQuestion : '';
    const remoteOpen = typeof channel.open === 'boolean' ? channel.open : true;
    const remoteSessionStartTime = channel.started_at ? channel.started_at : null;
    const moderator_id = channel.moderator_id ? channel.moderator_id : null;

    setSessionStarted(remoteSessionStarted);
    props.setParentSessionStarted(remoteSessionStarted);
    setCurrentProgress(remoteProgress);
    setCurrentQuestion(remoteCurrentQuestion)
    setSessionStartTime(remoteSessionStartTime);
    // console.log('remoteOpen', remoteOpen);

    const remoteUserStats = channel.userStats ? channel.userStats : {};
    //for all members in the channel but not in userStats, set their word count to 0
    const newUserStats = remoteUserStats;
    if (members) {
      setOnlineMembers(members);

      members.forEach(member => {
        const memberId = member.user_id;
        // Check if memberId is not in remoteUserStats
        const simplifiedUser = {
          // userID: user.userID,
          nickname: member.nickname,
          // role: user.role 
        };
        if (!remoteUserStats[memberId]) {
          const newUserStat = { wordCount: 0, user: simplifiedUser };
          // console.log('newUserStat', newUserStat);
          newUserStats[memberId] = newUserStat;
        }
      });
    }

    setAllUserStats(remoteUserStats);

    if (remoteOpen == false) {
      props.setChannelClosed(true);
      props.setChannelSummaryData({
        ended_at: new Date().toISOString(),
        started_at: remoteSessionStartTime,
        members: members,
        userStats: newUserStats,
        moderator_id
      })
    }
  }

  const getChannelData = () => {

    console.log('init channel data', channel);
    // console.log('channel.state.messages', channel.state.messages);
    const members = channel.state.members;
    const remoteSessionStarted = channel.data.started;
    const remoteProgress = channel.data.currentProgress ? channel.data.currentProgress : 0;
    const remoteCurrentQuestion = channel.data.currentQuestion ? channel.data.currentQuestion : '';
    const remoteOpen = typeof channel.data.open === 'boolean' ? channel.data.open : true;
    const remoteSessionStartTime = channel.data.started_at ? channel.data.started_at : null;
    const remoteSessionEndTime = channel.data.ended_at ? channel.data.ended_at : null;
    const moderator_id = channel.data.moderator_id ? channel.data.moderator_id : null;
    setSessionStarted(remoteSessionStarted);
    props.setParentSessionStarted(remoteSessionStarted);
    setCurrentProgress(remoteProgress);
    setCurrentQuestion(remoteCurrentQuestion)
    setSessionStartTime(remoteSessionStartTime);
    // console.log('remoteOpen', remoteOpen);

    const remoteUserStats = channel.data.userStats ? channel.data.userStats : {};
    if (remoteOpen == false) {
      props.setChannelClosed(true);
      props.setChannelSummaryData({
        ended_at: remoteSessionEndTime,
        started_at: remoteSessionStartTime,
        members: members,
        userStats: remoteUserStats,
        moderator_id: moderator_id
      })
    }
    else {
      props.setChannelSummaryData({
        // ended_at: remoteSessionEndTime,
        started_at: remoteSessionStartTime,
        members: members,
        userStats: remoteUserStats,
        moderator_id: moderator_id
      })
    }
    // console.log('remoteUserStats', remoteUserStats)

    //for all members in the channel but not in userStats, set their word count to 0
    const newUserStats = remoteUserStats;
    if (members) {
      // console.log('members', members)
      setOnlineMembers(Object.keys(members))
      Object.entries(members).forEach(([memberId, user]) => {
        // console.log('memberId', memberId)
        console.log('member each:', user.user)
        // Check if memberId is not in remoteUserStats
        const simplifiedUser = {
          // userID: user.userID,
          nickname: user.user.nickname ? user.user.nickname : 'Anonymous'
          // role: user.role 
        };
        if (!remoteUserStats[memberId]) {
          const newUserStat = { wordCount: 0, user: simplifiedUser };
          // console.log('newUserStat', newUserStat);
          newUserStats[memberId] = newUserStat;
        }
      });
    }

    setAllUserStats(remoteUserStats);

    if (remoteOpen == false) {
      // const exports = { data: channel.data, messages: channel.state.messages, language: userLanguage }
      // console.log('exports', exports)
      props.setChannelClosed(true);
    }

  }

  const getAllMessages = async (writeToFirebase = true) => {
    try {
      console.log('Fetching channel messages', channel);
      const messages = channel.state.messages;
      const data = channel.data;
      const startedAt = data?.started_at;
      const documentName = `focus_group_${startedAt ? startedAt.replace(/[^a-zA-Z0-9]/g, '_') : 'default'}`;
      const objectToWrite = { data, messages };

      if (writeToFirebase) {
        const surveyChannelRef = doc(firestore, 'focus_group_messages', documentName);
        await setDoc(surveyChannelRef, objectToWrite, { merge: true });
        console.log('updated firestore focus_group_messages with messages', channel.id, objectToWrite);
      }

      // Export to local JSON file
      const jsonBlob = new Blob([JSON.stringify(objectToWrite)], { type: 'application/json' });
      // Using file-saver
      // saveAs(jsonBlob, `${documentName}.json`);
      // Without file-saver, uncomment the lines below and comment out the saveAs line
      // const downloadLink = document.createElement('a');
      // downloadLink.href = URL.createObjectURL(jsonBlob);
      // downloadLink.download = `${documentName}.json`;
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);

      return objectToWrite;
    } catch (error) {
      console.error('Error fetching messages or updating Firestore:', error);
      throw error;
    }
  };

  const exportAllMessages = async (objectToWrite = null) => {
    try {
      if (objectToWrite) {
        const startedAt = objectToWrite?.data.started_at;
        const documentName = `focus_group_${startedAt ? startedAt.replace(/[^a-zA-Z0-9]/g, '_') : 'default'}`;
        // Reference to the document in the 'focus_group_messages' collection with the ID of the channel
        const surveyChannelRef = doc(firestore, 'fg_messages_exports', documentName);
        // Write the data to Firestore, merging with any existing document to avoid overwriting
        await setDoc(surveyChannelRef, objectToWrite, { merge: true });
        // Log the update
        console.log('exported firestore focus_group_messages with messages', documentName, objectToWrite);
      }
    } catch (error) {
      // Handle any errors that occur during the fetch/write process
      console.error('Error fetching messages or updating Firestore:', error);
      throw error; // Rethrow the error to handle it in the calling code if needed
    }
  };

  useEffect(() => {
    console.log('allUserStats', allUserStats);
    if (allUserStats && userData) {
      if (allUserStats[userData.userID]) {
        setUserWordCount(allUserStats[userData.userID].wordCount)
      }
    }
  }, [allUserStats, userData])

  useEffect(() => {
    if (props.mode == 'chat') {
      channel.on('message.new', handleUserMessage);
    }
    channel.on('channel.updated', handleChannelUpdate);

    return () => {
      if (props.mode == 'chat') {
        channel.off('message.new', handleUserMessage);
      }

      channel.off('channel.updated');

    };
  }, [channel, isModerator, props.mode, props.isTTSon, surveyQuestions, currentProgress, userData]);

  useEffect(() => {
    getChannelData();
    const timer = setInterval(() => {
      // Check the condition before calling getAllMessages
      if (channel.data.started) {
        console.log('Automatic saving in progress...');
        getAllMessages(true); // Write to Firebase as well
      }
      else {
        console.log('Session not started or already completed. No automatic saving.');
      }
    }, 15000);
    // Clear the interval on cleanup
    return () => clearInterval(timer);
  }, [channel]);

  const handleStartStopSession = async () => {
    // try {
    let focusGroupName = 'the focus group';
    if (focusGroupData) {
      focusGroupName = focusGroupData.name;
    }
    if (!sessionStarted) { //Start session

      const text = 'Moderator has started the session! Please feel free to engage in the conversation.';
      await channel.truncate({
        'hard_delete': true,
        'skip_push': false,
        'message': {
          text,
          'user_id': 'chandana2401'
        }
      });
      //for all members in the channel, set their word count to 0
      const memberIds = Object.keys(channel.state.members);
      const newUserStats = {}
      console.log('memberIds', memberIds)

      if (memberIds.length > 0) {
        //set their word counts to 0
        memberIds.forEach(memberId => {
          const user = channel.state.members[userData.userID].user;

          const simplifiedUser = {
            // userID: user.userID,
            nickname: user.nickname,
            // role: user.role 
          };
          const newUserStat = { wordCount: 0, user: simplifiedUser };
          console.log('newUserStat (started)', newUserStat);
          newUserStats[memberId] = newUserStat;
        });
        await channel.updatePartial({ set: { userStats: newUserStats } });
      }
      await channel.updatePartial({
        set: {
          started: true,
          moderator_id: userData.userID,
          moderator_info: userData,
          currentProgress: 0,
          currentQuestion: '',
          started_at: new Date().toISOString()
        }
      })
    } else { //Close session
      await channel.updatePartial({ set: { started: false, open: false, currentProgress: 0, currentQuestion: '' } })

      props.setChannelClosed(true);
      const objectToWrite = { data: channel.data, messages: channel.state.messages };
      const end_time = new Date().toISOString();
      objectToWrite.data.ended_at = end_time;
      await exportAllMessages(objectToWrite);
      console.log('truncating channel!', channel, channel.id, channel.data.name);
      await channel.truncate({
        'hard_delete': true,
        'skip_push': false,
        'message': {
          'text': `Welcome to ${focusGroupName}! The moderator will start the session shortly! `,
          'user_id': 'chandana2401'
        }
      });
      await delay(2000);

      await channel.updatePartial({
        set: {
          started: false,
          open: true,
          currentProgress: 0,
          currentQuestion: '',
          moderator_id: null,
          moderator_info: null,
          userStats: null,
        }
      })

      // await fetch(`${process.env.REACT_APP_SERVER_URL}/api/resetChannel?channelID=${channel.id}&channelName=${channel.data.name}`);
      console.log('channel truncated!');
      const memberIds = Object.keys(channel.state.members);
      if (memberIds.length > 0) {
        //go through all membersIds and remove all the associated custom data with their memberId
        console.log('removing', memberIds)
        await channel.updatePartial({ unset: ["userStats"] });
        await channel.removeMembers(memberIds);

      }
      await delay(1000);

      // }
      // } catch (error) {
      //   console.error('Error handling the session:', error.message);
      // }
    };
  }
  const handleLeaveSession = async () => {
    try {
      const { userData, focusGroupId } = props;

      const focusGroupDocRef = doc(firestore, 'focus_groups', focusGroupId);
      const focusGroupDocSnapshot = await getDoc(focusGroupDocRef);

      if (focusGroupDocSnapshot.exists()) {
        const focusGroupData = focusGroupDocSnapshot.data();
        const channelName = focusGroupData.name;
        console.log('channel leaving...', channel)
        if (!channel.data.started) {
          console.log('Leaving session (not started)...');
          await channel.removeMembers([userData.userID]);
        }

        else {
          console.log('Leaving session already started!');
        }
        navigate(-1)
        // navigate('/focus-groups');
      } else {
        console.error('Focus group document does not exist.');
      }
    } catch (error) {
      console.error('Error leaving the session:', error.message);
    }
  };

  useEffect(() => {
    const fetchSurveyQuestions = async () => {
      try {
        const focusGroupDocRef = doc(firestore, 'focus_groups', props.focusGroupId);
        const focusGroupDocSnapshot = await getDoc(focusGroupDocRef);

        if (focusGroupDocSnapshot.exists()) {
          const focusGroupData = focusGroupDocSnapshot.data();
          const selectedTopic = focusGroupData.selected_topic;

          if (selectedTopic) {
            const surveyDocRef = doc(firestore, 'survey_questions', selectedTopic);
            const surveyDocSnapshot = await getDoc(surveyDocRef);

            if (surveyDocSnapshot.exists()) {
              const surveyData = surveyDocSnapshot.data();
              const questionsArray = surveyData.questions || [];
              const extractedQuestions = questionsArray.map((question) => question.variations);
              setSurveyQuestions(extractedQuestions);
              console.log('surveyQuestions', extractedQuestions);
            } else {
              console.error(`Document '${selectedTopic}' does not exist in survey_questions collection.`);
            }
          } else {
            console.error('No selected topic found in the focus group document.');
          }
        } else {
          console.error('Focus group document does not exist.');
        }
      } catch (error) {
        console.error('Error fetching survey questions:', error.message);
      }
    };


    fetchSurveyQuestions();

  }, [isModerator, props.focusGroupId]);

  const handleQuestionSelection = (selectedQuestion) => {
    setSelectedQuestion(selectedQuestion);
  };

  const handleAskQuestion = async () => {
    const currentQuestion = surveyQuestions[currentProgress];

    if (sessionStarted) {
      channel.sendMessage({
        text: selectedQuestion,
      });
      if (props.isTTSon) {
        await playSynthesizedAudio(selectedQuestion);
      }
      else {
        console.log(props.isTTSon, 'props.isTTSon')
      }
      console.log('selectedQuestion', selectedQuestion)
      channel.updatePartial({ set: { currentProgress: currentProgress + 1, currentQuestion: selectedQuestion } });
      setSelectedQuestion('');
      try {
        console.log(surveyQuestions, currentQuestion);
      } catch (error) {
        console.error('Error sending the message:', error.message);
      }

    }
  };

  const handleSkipQuestion = () => {
    if (sessionStarted) {
      // setCurrentProgress(currentProgress + 1);
      channel.updatePartial({ set: { currentProgress: currentProgress + 1 } });
      setSelectedQuestion('');

    }
  };

  // const readOutQuestion = () => {
  //   const speechSynthesis = window.speechSynthesis;
  //   const message = new SpeechSynthesisUtterance(currentQuestion);
  //   speechSynthesis.speak(message);
  // };  
  useEffect(() => {
    console.log(onlineMembers, 'onlineMembers now')
  }, [onlineMembers])
  return (
    <div>
      <div style={{ justifyContent: 'start', alignItems: 'center' }}>

        {isModerator && props.mode == 'chat' ? <Row className='text-center'>
          <Col md='2'>
            {/* <ChannelHeader {...props} /> */}
            <div className='text-left ml-3 py-1'>
              {focusGroupData && <div>
                <b>{focusGroupData.name}</b>: <strong className='text-primary'>{onlineMembers.length}</strong> member(s)</div>
              }

              {sessionStarted && <div style={{ marginBottom: 0 }}>
                Word Count: <strong className='text-primary'>{userWordCount}
                </strong></div>}
              <Timer sessionStarted={sessionStarted} sessionStartTime={sessionStartTime} />
            </div>

          </Col>
          <Col md='1'></Col>
          <Col md='6'>
            {sessionStarted && <>
              {currentProgress < surveyQuestions.length ? <> <Dropdown onSelect={(eventKey) => handleQuestionSelection(eventKey)}>
                <Dropdown.Toggle variant="secondary" id="drop
                down-basic" style={{ width: '100%', margin: 10, whiteSpace: 'normal' }}>
                  {truncateString(selectedQuestion, 100) || "Please select a question"}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: '100%' }}>
                  {surveyQuestions[currentProgress].map(question =>
                    <Dropdown.Item key={currentProgress} eventKey={question} style={{ whiteSpace: 'normal' }}>
                      {question}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>


              </Dropdown>

                <div>
                  {selectedQuestion != '' &&
                    <Button variant='success' onClick={handleAskQuestion}>
                      Ask
                    </Button>}
                  <Button variant='outline-secondary' onClick={handleSkipQuestion}>
                    Skip
                  </Button>
                </div>

              </> : <div style={{ padding: 10 }}>
                <h3>End of Question List! 🎉🎉🎉</h3></div>}
              <div className='text-center mb-1'>
                <p style={{ marginBottom: 1 }}>Current Question: </p>
                <strong style={{ color: 'var(--bluedark)', fontSize: 17 }}>{currentQuestion}</strong>
              </div>
            </>
            }

          </Col>

          <Col md="3" style={{ textAlign: 'right', padding: 10 }}>
            {isModerator && (
              <Button
                size={isMobile ? 'sm' : 'lg'}
                variant={sessionStarted ? 'outline-warning' : 'primary'}
                onClick={handleStartStopSession}
              >
                {sessionStarted ? 'Stop Session' : 'Start Session'}
              </Button>
            )}

            <Button
              size={isMobile ? 'sm' : 'lg'}
              style={{ marginRight: 10 }}
              variant='danger'
              onClick={handleLeaveSession}
            >
              Leave Session
            </Button>
            <div style={{ marginRight: 10 }}>
              {isModerator && allUserStats && (
                <Dropdown as={ButtonGroup} className='mr-5' style={{ width: '100%', marginTop: 5 }}>
                  <Dropdown.Toggle as={CustomToggle} style={{ width: '100%' }}>
                    Monitor Word Counts <BsFileBarGraph style={{ marginLeft: 1, marginBottom: 5, fontSize: 17 }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu} style={{ width: '100%' }}>
                    {Object.entries(allUserStats).map(([userID, userStats]) => {
                      // Check if userStats.user is defined
                      if (!userStats.user) return null; // Skip rendering this item if user data is not available
                      return (
                        <Dropdown.ItemText key={userID} nicknameProp={userStats.user.nickname + " - " + userID}>
                          <Row>
                            <Col xs={6} className="text-start">
                              {userStats.user.nickname} {userID === userData.userID && <span>(<i>You</i>)</span>}:
                            </Col>
                            <Col xs={6} className="text-end">
                              <strong className='text-primary'>{userStats.wordCount}</strong> word(s)
                            </Col>
                          </Row>
                        </Dropdown.ItemText>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}

            </div>

          </Col>

        </Row>
          : <Row className='text-center'>
            <Col md='2'>
              {/* <ChannelHeader {...props} /> */}
              <div className='text-left ml-3 mb-2 py-1'>
                {focusGroupData && <div>
                  <b>{focusGroupData.name}</b>: <strong className='text-primary'>{onlineMembers.length}</strong> member(s)</div>
                }
                {sessionStarted && props.mode == 'chat' && <p style={{ marginBottom: 0 }}>
                  Word Count: <strong style={{ color: 'var(--bluedark)' }}>{userWordCount}
                  </strong></p>}
                <Timer sessionStarted={sessionStarted} sessionStartTime={sessionStartTime} />
              </div>
            </Col>
            <Col md='8'>
              {sessionStarted && <div className='text-center'>
                <p style={{ marginBottom: 1, marginTop: 5 }}>Current Question: </p>
                <h5 style={{ color: 'var(--bluedark)' }}><strong>{currentQuestion}</strong></h5>
                {/* <div
                className={"read-aloud-button"}
                onClick={readOutQuestion}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                >
                  <FaVolumeUp style={{ fontSize: 30 }} /> Read question
                </div> */}
              </div>}

            </Col>
            <Col md='2' style={{ textAlign: 'right' }}>
              <Button
                style={{ margin: 10 }}
                variant='danger'
                onClick={handleLeaveSession}
                size={isMobile ? 'sm' : 'lg'}
              >
                Leave Session
              </Button>
            </Col>
          </Row>
        }
        {sessionStarted && <div style={{ marginLeft: 20, marginRight: 20 }}>
          <ProgressBar style={{ height: 15 }}
            animated now={currentProgress / surveyQuestions.length * 100}
            max={100} /></div>}
      </div>
    </div >

  );
};

const FakeChannelHeader = (props) => {
  const { channel } = useChannelStateContext();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const sessionStartTime = new Date().toISOString();
  useEffect(() => {
    if (channel) {
      const remoteSessionStarted = channel.data.started;
      console.log('channel', channel, 'remoteSessionStarted', remoteSessionStarted)
      props.setParentSessionStarted(remoteSessionStarted);
    }

  }, [channel]);

  return (
    <div>
      <div style={{ justifyContent: 'start', alignItems: 'center' }}>

        <Row className='text-center'>
          <Col md='2'>
            {/* {!isMobile && <ChannelHeader {...props} />} */}

            <div className='text-left mx-2 stats-container' data-tour='current-stats'>
              <div style={{ marginBottom: 0 }}>
                Word Count: <strong className='text-primary'>{100}
                </strong></div>
              <Timer sessionStarted={true} sessionStartTime={sessionStartTime} />
            </div>

          </Col>
          <Col md='1'></Col>
          <Col md='6'>
            {props.isModerator && <div data-tour='next-question'>
              <Dropdown >
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '100%', margin: 10, whiteSpace: 'normal' }}>
                  "How do you think social media affects your mental health?"
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: '100%' }}>
                  <Dropdown.Item eventKey="1" style={{ whiteSpace: 'normal' }}>
                    "Do you feel more connected or isolated because of social media?"
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" style={{ whiteSpace: 'normal' }}>
                    "Have you ever taken a break from social media? If so, how did it impact your well-being?"
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3" style={{ whiteSpace: 'normal' }}>
                    "What measures do you take to ensure a healthy relationship with social media?"
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div>
                <Button variant='success'>
                  Ask
                </Button>
                <Button variant='outline-secondary'>
                  Skip
                </Button>
              </div>
            </div>}

            <div className='text-center mb-1' data-tour='current-question'>
              <p style={{ marginBottom: 1 }}>Current Question: </p>
              <strong style={{ color: 'var(--bluedark)', fontSize: 17 }}>How do you think we can promote a healthier relationship with social media?</strong>
              <div style={{ marginLeft: 20, marginRight: 20 }}>
                <ProgressBar style={{ height: 15 }}
                  animated now={1 / 15 * 100}
                  max={100} /></div>
            </div>
          </Col>

          <Col md="3" style={{ textAlign: 'right', marginRight: 0, padding: 10 }}>
            {props.isModerator && <Button variant='primary' data-tour="start-session"
              size={isMobile ? 'sm' : 'lg'}>
              Start Session
            </Button>}
            <Button
              variant='danger'
              data-tour="leave-session"
              size={isMobile ? 'sm' : 'lg'}
            >
              Leave Session
            </Button>
            <div style={{ marginRight: 10 }} >
              {props.isModerator && (
                <Dropdown as={ButtonGroup} className='mr-5' style={{ width: '100%', marginTop: 5 }} show >
                  <Dropdown.Toggle as={CustomToggle} style={{ width: '100%' }}>
                    Monitor Word Counts <BsFileBarGraph style={{ marginLeft: 1, marginBottom: 5, fontSize: 17 }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: '100%' }} data-tour="monitor-word-count">
                    <Dropdown.ItemText>
                      <Row>
                        <Col xs={6} className="text-start">
                          Alice:
                        </Col>
                        <Col xs={6} className="text-end">
                          <strong className='text-primary'>120</strong> word(s)
                        </Col>
                      </Row>
                    </Dropdown.ItemText>
                    <Dropdown.ItemText>
                      <Row>
                        <Col xs={6} className="text-start">
                          Bob:
                        </Col>
                        <Col xs={6} className="text-end">
                          <strong className='text-primary'>95</strong> word(s)
                        </Col>
                      </Row>
                    </Dropdown.ItemText>
                    <Dropdown.ItemText>
                      <Row>
                        <Col xs={6} className="text-start">
                          Charlie:
                        </Col>
                        <Col xs={6} className="text-end">
                          <strong className='text-primary'>150</strong> word(s)
                        </Col>
                      </Row>
                    </Dropdown.ItemText>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>

          </Col>
        </Row>
      </div>
    </div >
  );
};

export const CustomMessageInput = (props) => {
  const { t } = useTranslationContext();
  const { sendMessage } = useChannelActionContext();
  const { additionalTextareaProps } = useMessageInputContext();
  const [isSending, setIsSending] = useState(false);
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [editableTranscript, setEditableTranscript] = useState('');
  const [lastTranscript, setLastTranscript] = useState('');
  const { isTTSon, setIsTTSOn, isPlaying, setIsPlaying, channelClosed } = additionalTextareaProps;
  const startListening = () => SpeechRecognition.startListening({ continuous: true });
  const stopListening = () => SpeechRecognition.stopListening();

  const prevIsPlayingRef = useRef();
  const prevListeningRef = useRef();
  console.log('additionalTextareaProps', additionalTextareaProps)

  if (channelClosed) {
    console.log('channelClosed! Stop listening')
    stopListening();
  }
  const toggleRecording = () => {
    if (listening) {
      SpeechRecognition.stopListening();
      setLastTranscript(transcript);
    } else {
      startListening();
    }
  };

  useEffect(() => {
    console.log(`isPlaying: ${isPlaying}, listening: ${listening}`);
    console.log(`prevIsPlaying: ${prevIsPlayingRef.current}, prevListening: ${prevListeningRef.current}`);
    if (isPlaying) {
      if (listening) {
        console.log('Stopping listening because isPlaying is true');
        stopListening();
        prevListeningRef.current = true;
      }
    } else {
      if (prevListeningRef.current == true && !channelClosed) {
        console.log('Restarting listening because isPlaying changed to false');
        startListening();
      }
    }
    // Update refs
    prevIsPlayingRef.current = isPlaying;
  }, [isPlaying, listening, startListening, stopListening, channelClosed]);

  const togglePlay = () => {
    if (!isPlaying)
      setIsTTSOn(!isTTSon);
  };
  const windowHeight = useWindowHeight();
  const marginBottomStyle = calculateMarginBottom(windowHeight);

  useEffect(() => {
    const newWords = transcript.substring(lastTranscript.length);
    setEditableTranscript((prev) => prev + newWords);
    setLastTranscript(transcript);
    console.log('transcript', transcript, 'lastTranscript', lastTranscript)
  }, [transcript, lastTranscript]);

  useEffect(() => {
    console.log('editableTranscript', editableTranscript)
  }, [editableTranscript])

  const customSendMessage = async (messageObject) => {
    messageObject.text = editableTranscript;
    // if (isModerator) {
    //   messageObject.role = 'moderator';
    // }
    // else {
    //   messageObject.role = 'user';
    // }
    setIsSending(true);
    console.log('messageObject', messageObject)
    await sendMessage(messageObject);

    setIsSending(false);
    resetTranscript();
    setLastTranscript('');
    setEditableTranscript('');
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: marginBottomStyle }}>
      <div
        className={`mic-circle ${listening ? 'listening' : ''}`}
        onClick={toggleRecording}
        style={{ pointerEvents: isSending ? 'none' : 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <FaMicrophone
          className={`fa-microphone ${listening ? 'listening' : ''}`}
          style={{ fontSize: 30, margin: 'auto' }}
        />
      </div>
      <div onClick={togglePlay} style={{ marginLeft: 17, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {isTTSon || isPlaying ?
          <HiOutlineSpeakerWave className={`hi-speaker`}
            style={{ fontSize: 35, margin: 'auto' }} /> :
          <HiOutlineSpeakerXMark className={`hi-speaker-off`}
            style={{ fontSize: 35, margin: 'auto' }} />
        }
      </div>
      <div className='str-chat__message-input'>
        <div className='str-chat__message-input-inner'>
          <div className='str-chat__message-textarea-container'>
            <div className='str-chat__message-textarea-with-emoji-picker'>
              <ChatAutoComplete
                placeholder={t('Type your message or start speaking...')}
                onChange={(e) => setEditableTranscript(e.target.value)}
                value={editableTranscript}
                handleSubmit={customSendMessage}
              />
            </div>
          </div>
          <SendButton disabled={editableTranscript.trim() == ''} sendMessage={customSendMessage} />
        </div>
      </div>
    </div>
  );
  // return (
  //     <div style={{ display: 'flex', alignItems: 'center' }}>
  //       <div
  //         className={`mic-circle ${listening ? 'listening' : ''}`}
  //         onClick={toggleRecording}
  //         style={{ pointerEvents: isSending ? 'none' : 'auto' }}
  //       >
  //         <FaMicrophone
  //           className={`fa-microphone ${listening ? 'listening' : ''}`}
  //           style={{
  //             fontSize: 30,
  //           }}
  //         />
  //       </div>
  //       <MessageInput
  //         {...props}
  //         overrideSubmitHandler={customSendMessage}
  //         additionalTextareaProps={{
  //           placeholder: 'Type your message or start speaking...',
  //           value: editableTranscript,
  //           onChange: (e) => setEditableTranscript(e.target.value),
  //         }}
  //       />
  //     </div>
  //   );
};

export const FakeMessageInput = (props) => {
  const { t } = useTranslationContext();
  const windowHeight = useWindowHeight();
  const marginBottomStyle = calculateMarginBottom(windowHeight);

  return (

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: marginBottomStyle }}>
      <div
        className={`mic-circle`}
        style={{ pointerEvents: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <FaMicrophone
          className={`fa-microphone`}
          data-tour="microphone"
          style={{ fontSize: 30, margin: 'auto' }}
        />
      </div>
      <div style={{ marginLeft: 17, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

        <HiOutlineSpeakerWave className={`hi-speaker`} data-tour="speaker"
          style={{ fontSize: 35, margin: 'auto' }} />

      </div>
      <div className='str-chat__message-input' >
        <div className='str-chat__message-input-inner' data-tour='custom-message-input'>
          <div className='str-chat__message-textarea-container'>
            <div className='str-chat__message-textarea-with-emoji-picker'>
              <ChatAutoComplete
                placeholder={t('Type your message or start speaking...')}
                disabled={true}
              />
            </div>

          </div>
          <SendButton />
        </div>
      </div>

    </div>

  );
};


const CustomMessage = () => {
  const { isMyMessage, message } = useMessageContext();
  // console.log('message', message, 'isMyMessage', isMyMessage());
  const { channel } = useChannelStateContext();
  let isSenderModerator = false;
  if (message.user && message.user.id === channel.data.moderator_id) {
    isSenderModerator = true;
  }
  if (message.user && message.user.role === 'moderator') {
    isSenderModerator = true;
  }
  const isOwnMessage = isMyMessage();
  // console.log('CustomMessage isModerator Message', isSenderModerator, isOwnMessage);

  return <div className={isSenderModerator && !isOwnMessage ? "moderator-message" : 'user-message'}>
    {/* <p>{message.user.nickname}</p> */}
    {/* <Attachment /> */}
    <MessageSimple />
  </div>
}

const ChatPage = ({ mode }) => {
  // console.log('mode', mode)
  const { t, userLanguage } = useTranslationContext();

  const { userData } = useAuth();
  const isModerator = userData && userData.role === 'moderator' ? true : false;
  let sampleMessages = [];
  if (userData)
    sampleMessages = returnSampleMessages(userData.userID, userData.role);
  const { focusGroupId } = useParams();
  const [uniqueId, setUniqueId] = useState(generateUniqueId(5));

  const { client } = useChatContext();
  const chatClient = client;
  const [channelReady, setChannelReady] = useState(false);

  const [channelClosed, setChannelClosed] = useState(false);
  const [channelSummaryData, setChannelSummaryData] = useState(null);

  const [sessionStarted, setSessionStarted] = useState(null);
  const [showMessageInput, setShowMessageInput] = useState(false);

  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  const instructions = "We invite you to complete the survey to the best of your ability. Rest assured that your responses are confidential and will only be reviewed by the UMKC research team. The data collected is crucial for reporting on the program's effectiveness and will be used exclusively for funders' reports. We are committed to maintaining the highest level of confidentiality and security with your information."
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;


  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const moderatorSteps = [
    {
      // Welcome step
      selector: '',
      content: (
        <div style={{ color: 'black' }}> {/* Adjust the width here */}
          <div className='text-center' ><h3>Welcome to the focus group session, moderator!</h3></div>

          {instructions}
          <p></p>
          <p>Click the <FaArrowRightLong style={{ marginBottom: 3 }} /> or press the Right arrow key on your keyboard to proceed with the instructions to navigate through the web:</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {
      // Start Session button with additional note
      selector: '[data-tour="start-session"]',
      content: () => (
        <div>
          <p>As a moderator, you will click here to start the session.</p>
        </div>
      ),
    },
    {
      // Leave Session button with additional note
      selector: '[data-tour="leave-session"]',
      content: () => (
        <div>
          <p>Click here to leave the session. </p>

          <p><strong>Note:</strong> Once left, you cannot rejoin to continue the session.</p>
        </div>
      ),
    },
    {

      selector: '[data-tour="current-question"]',
      content: () => (
        <div>
          <p>This section helps you track the current question and the overall progress of the session.</p>
          <p>Note: These are only demo questions. Real questions may vary.</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {

      selector: '[data-tour="next-question"]',
      content: () => (
        <div>
          <p>You can preview the next question here. When you are ready, you can start asking the question, or skip the question and advance to the one after that.</p>
          <p>Note: These are only demo questions. Real questions may vary.</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {

      selector: '[data-tour="monitor-word-count"]',
      content: () => (
        <div>
          <p>As a moderator, you can track other participants' word counts.</p>
        </div>
      ),
    },
    {

      selector: '[data-tour="message-list"]',
      content: () => (
        <div>
          <p>This is the main conversation panel. You can see moderator's and other participants' responses here.</p>
          <p>Note: this is only a demo version. Real questions may vary.</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {

      selector: '[data-tour="custom-message-input"]',
      content: () => (
        <div>
          <p>You can reply by typing or speaking. You can click <IoMdSend style={{ marginBottom: 3 }} /> to send or press ENTER </p>
        </div>
      ),
    },

    {

      selector: '[data-tour="microphone"]',
      content: () => (
        <div>
          <p>You can click the icon here to activate speech recognition without using the keyboard.</p>
          <p>A popup may appear to ask for your permission. You need to allow this permission to use your microphone in order to use this feature.</p>
          {/* {!isMobile && <div className='text-center'><img src={microphone_permission_img} alt="Microphone Permission"></img></div>} */}
          <p><strong>Note:</strong> This feature is only available for compatible browsers. Safari, Google Chrome and Edge are highly recommended.</p>
          {!isMobile && <p>
            <strong>If you accidentally blocked the microphone permission:</strong>
            <ol>
              <li>Click the lock icon on the left side of the address bar.</li>
              <li>Find the Microphone permission in the dropdown menu.</li>
              <li>Change the permission to 'Allow'.</li>
              <li>Reload the page for the changes to take effect.</li>
            </ol>
            For detailed instructions, visit <a href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer">Google Support</a>.
          </p>}
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    // {

    //   selector: '[data-tour="speaker"]',
    //   content: () => (
    //     <div>
    //       <p>You can click the icon here to toggle the speech synthesis (speech-out) feature On/Off.</p>
    //     </div>
    //   ),
    // },

  ];
  const participantSteps = [
    {
      // Welcome step
      selector: '',
      content: (
        <div style={{ color: 'black' }}> {/* Adjust the width here */}
          <div className='text-center' ><h3>Welcome to the focus group session, participant!</h3></div>

          {instructions}
          <p></p>
          <p>Click the <FaArrowRightLong style={{ marginBottom: 3 }} /> or press the Right arrow key on your keyboard to proceed with the instructions to navigate through the web:</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {
      // Leave Session button with additional note
      selector: '[data-tour="leave-session"]',
      content: () => (
        <div>
          <p>Click here to leave the session. </p>

          <p><strong>Note:</strong> Once left, you cannot rejoin to continue the session.</p>
        </div>
      ),
    },
    {

      selector: '[data-tour="current-question"]',
      content: () => (
        <div>
          <p>This section helps you track the current question and the overall progress of the session.</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {

      selector: '[data-tour="current-stats"]',
      content: () => (
        <div>
          <p>You can also view your word count and current session duration.</p>
        </div>
      ),
    },
    {

      selector: '[data-tour="message-list"]',
      content: () => (
        <div>
          <p>This is the main conversation panel. You can see moderator's and other participants' responses here.</p>
          <p>Note: this is only a demo version. Real questions may vary.</p>
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    {

      selector: '[data-tour="custom-message-input"]',
      content: () => (
        <div>
          <p>You can reply by typing or speaking. You can click <IoMdSend style={{ marginBottom: 3 }} /> to send or press ENTER </p>
        </div>
      ),
    },
    {
      selector: '[data-tour="microphone"]',
      content: () => (
        <div>
          <p>You can click the icon here to activate speech recognition without using the keyboard.</p>
          <p>A popup may appear to ask for your permission. You need to allow this permission to use your microphone in order to use this feature.</p>
          {/* {!isMobile && <div className='text-center'><img src={microphone_permission_img} alt="Microphone Permission"></img></div>} */}
          <p><strong>Note:</strong> This feature is only available for compatible browsers. Safari, Google Chrome and Edge are highly recommended.</p>
          {!isMobile && <p>
            <strong>If you accidentally blocked the microphone permission:</strong>
            <ol>
              <li>Click the lock icon on the left side of the address bar.</li>
              <li>Find the Microphone permission in the dropdown menu.</li>
              <li>Change the permission to 'Allow'.</li>
              <li>Reload the page for the changes to take effect.</li>
            </ol>
            For detailed instructions, visit <a href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer">Google Support</a>.
          </p>}
        </div>
      ),
      style: {
        // backgroundColor: '#bada55',
        maxWidth: isMobile ? 400 : 700
      },
    },
    // {
    // 
    //   selector: '[data-tour="speaker"]',
    //   content: () => (
    //     <div>
    //       <p>You can click the icon here to toggle the speech synthesis (speech-out) feature On/Off.</p>
    //     </div>
    //   ),
    // },
  ];
  const steps = isModerator ? moderatorSteps : participantSteps;
  const messageListStep = isModerator ? 6 : 4
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTTSon, setIsTTSOn] = useState(false);

  useEffect(() => {
    const initChat = async () => {
      if (!userData || !userData.userID || !userData.token) {
        console.error('Invalid user object. Missing user ID or Stream Chat token.');
        return;
      }

      try {
        // const apiURL = `${process.env.REACT_APP_SERVER_URL}/api/addmembers?channelID=${focusGroupId}&userID=${userData.userID}`;
        // await fetch(apiURL);
        setChannelReady(true);
        console.log('userData', userData)
        const channel = client.channel('focus_group', focusGroupId);
        await channel.watch();
        let channel_role = 'channel_member'
        if (userData.role === 'moderator' && mode == 'chat') {
          channel_role = 'channel_moderator'
        }
        // else if (mode == 'observe') {
        //   channel_role = 'channel_observer'
        // }
        await channel.addMembers([{ user_id: userData.userID, channel_role: channel_role }]);
        console.log('added members', [userData.userID], channel_role)

      } catch (error) {
        console.error('Error initializing chat:', error.message);
      }
    };

    if (userData && !channelReady) {
      console.log('Initializing chat...');
      initChat();
    }


    // Clean up the event listener when the component unmounts
    return () => {

    };
  }, [userData, focusGroupId, channelReady, chatClient]);
  useEffect(() => {
    console.log('chatClient', chatClient)
  }, [chatClient])
  useEffect(() => {
    console.log('channelSummaryData', channelSummaryData)
  }, [channelSummaryData])
  useEffect(() => {
    if (mode === 'chat') {
      setShowMessageInput(sessionStarted)
      console.log('sessionStarted', sessionStarted)

      if (sessionStarted === false) {
        setIsTourOpen(true);
      }
      else if (sessionStarted === true) {
        setIsTourOpen(false);
      }
    }
    else {
      setShowMessageInput(false)

    }
  }, [sessionStarted, userData])
  useEffect(() => {
    console.log('isTourOpen', isTourOpen, "isModerator", isModerator)
  }, [isTourOpen, isModerator])
  //Create use effect for channelSummaryData
  useEffect(() => {
    if (channelSummaryData) {
      console.log('channelSummaryData', channelSummaryData)
    }
  }, [channelSummaryData])
  useEffect(() => {
    // Start the countdown when the channel is closed
    if (channelClosed) {
      const timerId = setInterval(() => {
        setCountdown((currentCountdown) => {
          if (currentCountdown <= 1) {
            clearInterval(timerId);
            // navigate(`/survey-fedback`);
            navigate('/qrcode?confirmation=' + uniqueId);
            // return <JoinSurveyFeedback/>;
          }
          return currentCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [channelClosed]);



  const themeStyle = {
    messageSimple: {
      content: {
        container: {
          borderWidth: 0,
        },
        containerInner: {
          borderWidth: 0,
        },
        deletedContainerInner: {
          borderWidth: 0,
        },
        textContainer: {
          borderWidth: 0,
        },
      },
    },
  };
  const customActions = {
    'Copy': (message) => {
      navigator.clipboard.writeText(message.text || '');
    },
  };

  // if (chatClient === null) return <LoadingPage />;
  // if (chatClient === undefined) return <ErrorPage />;
  return <>
    {!channelClosed ? <div style={{
      height: '99vh',
      overflow: 'hidden',
      padding: mode == 'chat' ? 0 : "3px 0px",
      border: mode == 'chat' ? 'none' : '4px dashed var(--primaryred)',
      borderRadius: 10,
    }}>

      {channelReady ? (
        <Chat client={chatClient}>
          <Channel
            channel={chatClient.channel('focus_group', focusGroupId)}
            Input={CustomMessageInput}
          >
            <Window>
              {!isTourOpen ? <CustomChannelHeader
                userData={userData}
                focusGroupId={focusGroupId}
                setParentSessionStarted={setSessionStarted}
                setChannelClosed={setChannelClosed}
                setChannelSummaryData={setChannelSummaryData}
                channelClosed={channelClosed}
                mode={mode}
                isTourOpen={isTourOpen}
                isTTSon={isTTSon}
                setIsTTSOn={setIsTTSOn}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
              /> : <FakeChannelHeader
                setParentSessionStarted={setSessionStarted}
                isModerator={isModerator}
              />}
              {isTourOpen && (
                currentStepIndex === messageListStep ? (
                  <div data-tour="message-list" style={{ height: "auto" }}>
                    <MessageList messages={sampleMessages} customMessageActions={customActions} Message={CustomMessage} />
                  </div>
                ) : (
                  <MessageList messages={sampleMessages} customMessageActions={customActions} Message={CustomMessage} />
                )
              )}

              {!isTourOpen && <MessageList customMessageActions={customActions} Message={CustomMessage} />}
              {/* <VirtualizedMessageList Message={CustomMessage} /> */}
              {isTourOpen && <FakeMessageInput />}
              {/* {showMessageInput ? (
                <CustomMessageInput />
              ) : <></>} */}
              {showMessageInput && <MessageInput
                additionalTextareaProps={{
                  isTTSon,
                  setIsTTSOn,
                  isPlaying,
                  setIsPlaying,
                  channelClosed,
                  isModerator,
                }}
                grow={true} />}
              {mode == 'observe' && <div className='text-center my-3'>
                <h4 className='blink-text'>
                  <strong><FaGlasses style={{ marginBottom: 5 }} /> You are observing...</strong></h4>
              </div>}
              {/* {mode === 'chat' && <CustomMessageInput sessionStarted={sessionStarted} />} */}
            </Window>
          </Channel>
        </Chat>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <LoadingIndicator size={100} />
        </div>
      )}
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        getCurrentStep={setCurrentStepIndex}
        onBeforeClose={() => setCurrentStepIndex(0)}

      />
    </div> : <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        width: '90%', // Adjust this value to change the width
        // maxWidth: '1200px', // Optional: Sets a maximum width
        margin: 'auto', // Centers the div on the page
      }}
    >
      {/* <LoadingIndicator size={100} /> */}
      <img src={unavailableImg} height={150}></img>
      <div style={{ marginTop: '30px', textAlign: 'center', width: '50%' }}> {/* Add some space between the indicator and the text */}
        <h4 style={{ marginBottom: 0 }}><b>The session has been closed.</b></h4>
        <h5>Thank you for participating in the focus group session!</h5>
        <h6>Please join the next available session.</h6>
        {channelSummaryData && <Row>
          <QRCodeGenerator channelSummaryData={channelSummaryData} targetUserID={userData.userID} userData={userData} uniqueId={uniqueId} />
        </Row>
        }

        <Button style={{ marginTop: 10 }} variant='outline-secondary' onClick={() => { navigate(-1); }}>Go Back</Button>
        {/* <Button onClick={() => { window.location.reload() }}>Refresh</Button> */}
        {/* <h6>You will be automatically redirected to the main lobby in <strong style={{ fontSize: 20 }}>{countdown}</strong> second(s) ...</h6> */}
        <h6>{t('You will be automatically redirected to the home page in') + ' '} <strong style={{ fontSize: 20 }}>{countdown}</strong> {t('second(s)')} ...</h6>

      </div>

    </div>

    }
  </>


};

export default ChatPage;

function truncateString(str, num) {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }
  return "Please select a question";
}
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const Timer = ({ sessionStarted, sessionStartTime, setCurrentTimeLength }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    let interval;

    if (sessionStarted && sessionStartTime) {
      // console.log('sessionStartTime', sessionStartTime)
      const startTime = new Date(sessionStartTime).getTime();

      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = Math.floor((currentTime - startTime) / 1000);
        setTime(elapsedTime);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [sessionStarted, sessionStartTime]);



  return (
    <div>
      {sessionStarted && time && <div>Current Session Time: <strong className='text-primary'>{formatTime(time)}</strong></div>}
    </div>
  );
};
const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const formattedHours = hours > 0 ? `${hours}:` : '';
  const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}:`;
  const formattedSeconds = `${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
};
export const formatTimeDifference = (startTime, endTime) => {
  const start = new Date(startTime).getTime();
  const end = new Date(endTime).getTime();

  // Calculate difference in seconds
  const timeDifference = Math.floor((end - start) / 1000);

  // Formatting time
  const hours = Math.floor(timeDifference / 3600);
  const minutes = Math.floor((timeDifference % 3600) / 60);
  const seconds = timeDifference % 60;

  const formattedHours = hours > 0 ? `${hours}:` : '';
  const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}:`;
  const formattedSeconds = `${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
};

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    const filterChildren = React.Children.toArray(children).filter(child => {
      // Access the nicknameProp for filtering
      const nickname = child.props.nicknameProp;
      if (nickname)
        return nickname.toLowerCase().includes(value.toLowerCase());
    });

    return (
      <div
        ref={ref}
        style={{ ...style, padding: '10px' }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          // style={{ width: "90%" }}
          className='my-1 w-90'
          placeholder="Filter user..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />

        <ul className="list-unstyled">
          {filterChildren}
        </ul>
      </div>
    );
  },
);



const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {/* &#x25bc; */}
  </a>
));


function calculateMarginBottom(windowHeight) {
  if (windowHeight <= 700) {
    return '5vh';
  } else if (windowHeight > 700 && windowHeight <= 800) {
    return '3vh';
  } else {
    return 5;
  }
}