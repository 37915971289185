import React, { useEffect, useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore } from 'firebase/firestore';
import { Form, Button, Container, Alert, CardBody, Row, Col, Card, CardImg } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Assets/images/welisten_logo_new_transparent.png'
import { faker } from '@faker-js/faker';
export const createStreamUser = async (userID, nickname) => {
  console.log("Creating new StreamChat user for", userID, nickname)
  const streamChatResponse = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/createUser?userID=${userID}&name=${nickname}`
  );

  if (!streamChatResponse.ok) {
    throw new Error('Error creating Stream Chat user');
  }

  const streamChatData = await streamChatResponse.json();
  return streamChatData; // Assuming the response contains the token
};
const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [firstNamePlaceholder, setFirstNamePlaceholder] = useState(faker.person.firstName());
  const [lastNamePlaceholder, setLastNamePlaceholder] = useState(faker.person.lastName());
  const [nicknamePlaceholder, setNicknamePlaceholder] = useState(
    `${faker.word.adjective().charAt(0).toUpperCase() + faker.word.adjective().slice(1)} ${faker.name.firstName()}`
  );


  const [emailPlaceholder, setEmailPlaceholder] = useState(faker.internet.email());
  const [passwordPlaceholder, setPasswordPlaceholder] = useState(faker.internet.password());


  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const firestore = getFirestore();
  const auth = getAuth();

  const validateForm = () => {
    const missingFields = [];
    if (!firstName.trim()) missingFields.push('First Name');
    if (!lastName.trim()) missingFields.push('Last Name');
    if (!nickname.trim()) missingFields.push('Nickname');
    if (!email.trim()) missingFields.push('Email');
    if (!password.trim()) missingFields.push('Password');

    if (missingFields.length > 0) {
      setError(`Please fill in the following fields: ${missingFields.join(', ')}`);
      return false;
    }
    return true;
  };


  const handleSignup = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      // Extract user ID
      const userID = user.uid;
      console.log('new UserID', userID);

      // Store additional user data in Firestore
      const userRef = doc(firestore, 'users', userID);
      await setDoc(userRef, {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        nickname: nickname.trim(),
        email: email.trim(),
        role: 'user',
        userID,
      });

      // Clear form fields
      setFirstName('');
      setLastName('');
      setNickname('');
      setEmail('');
      setPassword('');

      // Set success message and navigate to home page
      setSuccessMessage('Signup successful! Redirecting to main page...');
    } catch (err) {
      let errorMessage;
      switch (err.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'This email is already in use. Please try a different one.';
          break;
        default:
          errorMessage = 'An error occurred during sign-up. Please try again.';
      }
      setError(errorMessage);
    }

  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        navigate('/', { state: { justSignedUp: true } });
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  });

  return (
    <Container className="mt-5">
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Row className="justify-content-center">
        <Col lg="5">
          <Card className="bg-secondary shadow border-0">
            <div className='px-5 py-2'>
              <CardImg src={logo} height={100} width={40}></CardImg>
            </div>
            <CardBody className="px-lg-5 pb-lg-5">
              <div className="text-center  mb-4">
                <h5 className='text-muted'>Create your WeListen account</h5>
              </div>
              <Form onSubmit={handleSignup}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={firstNamePlaceholder}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={lastNamePlaceholder}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formNickname">
                  <Form.Label>Nickname</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={nicknamePlaceholder}
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={emailPlaceholder}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={passwordPlaceholder}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <div className='text-center my-3'>
                  <Button variant="primary" type="submit">
                    Sign Up
                  </Button>
                </div>
                <div className='text-right'>
                  <small>
                    Already have an account? <Link to="/">Login here</Link>
                  </small>
                </div>
              </Form>


            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
