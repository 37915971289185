// useAuth.js
import { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { StreamChat } from 'stream-chat';
import { doc, getDoc, getFirestore, setDoc } from '@firebase/firestore';
import { createStreamUser } from '../Participants/Auth/Signup';

function useAuth() {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    // Define necessary state for useClient
    const [clientParams, setClientParams] = useState({ user: null });

    const chatClient = useClient(clientParams);

    // const chatClient = useClient({
    //     apiKey: 'wv84w26ye3k7',
    //     user: userData,
    // });
    const db = getFirestore(); // Initialize Firestore
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const userRef = doc(db, 'users', currentUser.uid);
                console.log('authChanged -  currentUser: ', currentUser.uid)
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const data = userDoc.data();
                    const { userID, nickname } = data;
                    if (!data.token) {
                        const streamChatData = await createStreamUser(userID, nickname);
                        const { token } = streamChatData;

                        await setDoc(
                            userRef,
                            {
                                token,
                            },
                            { merge: true }
                        );
                        data.token = token;
                        data.image = `https://getstream.io/random_png/?id=${userID}&name=${nickname}`


                    }
                    setUserData({ ...data }); // Assuming 'role' is the field name in your users collection
                    setClientParams({ user: { ...data, id: data.userID, token: data.token } });
                }
                setUser(currentUser);

            }
            else {
                setUser(undefined);
            }
        });

        // Cleanup the listener on unmount
        return () => unsubscribe();
    }, [db]);

    return { user, userData, chatClient };
}


const useClient = ({ user }) => {
    const [chatClient, setChatClient] = useState(null);
    // const isConnecting = useRef(false);

    useEffect(() => {
        if (user && user.userID && user.token) {
            setChatClient(null);
            const client = new StreamChat('wv84w26ye3k7');

            const connectUser = async () => {
                // isConnecting.current = true;
                try {
                    await client.connectUser(user, user.token);
                    setChatClient(client); //successful valid client

                    // setChatClient(undefined); //test for error pages - error
                    // setChatClient(null); //still loading

                } catch (error) {
                    console.error('Error connecting user:', error);
                    setChatClient(undefined)
                }
            };

            connectUser();

            return () => {

                client.disconnectUser().then(() => {
                    console.log('connection closed');
                });
                setChatClient(undefined);

            };
        }
        else
            setChatClient(undefined);
    }, [user?.userID, user?.token]); // Dependencies

    return chatClient;
};

export default useAuth;
